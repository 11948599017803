import styled from "styled-components";
import { themes } from "../../../config/Themes";

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-right: 30px;

  @media (max-width: 1020px) {
    position: absolute;
    z-index: 1300;
    top: 0;
    right: 0;
    flex-flow: column;
    width: 200px;
    padding: 15px;
    box-sizing: border-box;
    background: ${themes.regular.white};
    margin-right: 0;
  }
`;

export const Button = styled.button`
  width: 30px;
  height: 30px;
  margin: 5px;
  box-sizing: border-box;
  background-color: none;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${props => props.url});

  :hover {
    cursor: pointer;
  }
`;

export const Label = styled.label`
  display: block;
  height: 100%;
  width: 100%;
  margin: 0;
`;

export const UploadFile = styled.input`
  width: 0.1px;
  height: 0.1px;
  overflow: hidden;
  opacity: 0;
`;

export const Backdrop = styled.div`
  @media (max-width: 1020px) {
    position: absolute;
    z-index: 1200;
    top: 0;
    right: 0;
    flex-flow: column;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, .2);
  }
`;