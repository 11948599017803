import ExportJson from "../ExportJson";
import ExportXls from "../ExportXls";
import ExportPdf from "../ExportPdf";
import ExportDocx from "../ExportDocx";
import ExportHtml from "../ExportHtml";
import ExportTxt from "../ExportTxt";
import ExportXml from "../ExportXml";
import ExportCsv from "../ExportCsv";


const HANDLERS = {
  json: ExportJson,
  xlsx: ExportXls,
  pdf: ExportPdf,
  docx: ExportDocx,
  html: ExportHtml,
  txt: ExportTxt,
  xml: ExportXml,
  csv: ExportCsv,
};

class ExportFactory {
  static getExportHandler(type, data) {
    const handler = HANDLERS[type];
    return handler ? new handler(data) : null;
  }
}

export default ExportFactory