import styled from "styled-components";
import { Link } from "react-router-dom";
import { themes } from "../../config/Themes";

export const Container = styled.div`
  margin-top: 18%;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

export const HomeLink = styled(Link)`
  font-weight: 600;
  color: ${themes.regular.white};

  :hover {
    color: ${themes.regular.white};
  }
`