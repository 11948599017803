import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { CurrentPage } from "./styled";

const MainCurrentPage = withTranslation()(
  withRouter(({ t, activeRoute, location }) => {
    const path = location.pathname;
    let currentPageName = activeRoute && t(activeRoute.nameToDisplay); 

    if (/\/(investigation)\/\w*/g.test(path)) currentPageName = "Investigation";
    if (/\/(targets)\/\d*/g.test(path)) currentPageName = "Targets";
    if (/\/(investigation)\/\d*\/(geomc)/g.test(path)) currentPageName = "Geomc Case";
    if (/\/(target)\/\d*\/(webint)/g.test(path)) currentPageName = "Webint Case";
    if (/\/(investigation)\/\d*\/(profiler)/g.test(path)) currentPageName = "Profiler case";
      
    return <CurrentPage>{currentPageName}</CurrentPage>;
  })
);

const mapStateToProps = (state) => {
  return {
    activeRoute: state.settings.activeRoute,
  };
};

export default connect(mapStateToProps)(MainCurrentPage);
