import React from "react";
import { useToasts } from "react-toast-notifications";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import styled from "styled-components";
import { themes } from "../../../../config/Themes";

export const ToastNotification = ({ appearance, children }) => {
  const { removeToast } = useToasts();
  const handleCloseToast = (e) => {
    e.preventDefault();
    removeToast();
  };
  let toast;
  switch (appearance) {
    case "error":
      toast = (
        <Error>
        {children}
        <CloseBtn onClick={handleCloseToast}>
          <CloseRoundedIcon fontSize="small"/>
        </CloseBtn>
      </Error>
      );
    break;
    case "success":
      toast = (
        <Success>
        {children}
        <CloseBtn onClick={handleCloseToast}>
          <CloseRoundedIcon fontSize="small"/>
        </CloseBtn>
      </Success>
      );
    break;
    case "warning":
      toast = (
        <Warning>
        {children}
        <CloseBtn onClick={handleCloseToast}>
          <CloseRoundedIcon fontSize="small"/>
        </CloseBtn>
      </Warning>
      );
    break;
    case "general":
      toast = (
        <General>
        {children}
        <CloseBtn onClick={handleCloseToast}>
          <CloseRoundedIcon fontSize="small"/>
        </CloseBtn>
      </General>
      );
    break;
    default:
  }
  return toast;
};

const Error = styled.div`
  position: relative;
  border-radius: 8px;
  background: ${themes.regular.toasts.error};
  color: ${themes.regular.white};
  padding: 10px 40px 10px 10px;
  margin: 5px 0;
  width: 350px;
`;

const Success = styled(Error)`
  background: ${themes.regular.toasts.success};
`;

const Warning = styled(Error)`
  background: ${themes.regular.toasts.warning};
`;

const General = styled(Error)`
  background: ${themes.regular.toasts.general};
`;

const CloseBtn = styled.button`
  padding: 0;
  border: none;
  background: none;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: ${themes.regular.white};

  :focus {
    outline: none;
  }
`;
