import {put, select, takeEvery} from "redux-saga/effects";
import axios from "axios";
import config from "../../config/config";
import {authHeader} from "../../helpers/authHeader";
import JobsAction from "./jobsAction";
import RootScopeAction from "../rootScope/rootScopeAction";
import {apiJobsService, apiStatService} from "../../config/api";
import {selectJobFilter} from "./jobsSelector";
import i18next from "i18next";

function* getJobsListSaga(action) {
  yield put(JobsAction.setJobsLoading(true));

  try {
    const {
      page = 0,
      size = 15,
      jobName = '',
      jobId = "",
      createdBy = null
    } = yield select(selectJobFilter);

    const filtersObject = {
      batch: size,
      skip: page * size
    }
    if (jobName) filtersObject.name = jobName;
    if (jobId) filtersObject.number = jobId * 1;
    if (createdBy) filtersObject.createdBy = createdBy;

    //const data = yield apiJobsService.getJobs(page, size);
    const data = yield apiJobsService.getJobs(filtersObject);
    console.log('data', data);
    yield put(JobsAction.setJobsList(data));
    yield put(JobsAction.setJobsLoading(false));
  } catch (e) {
    yield put(RootScopeAction.handleAPIError(e));
    yield put(JobsAction.setJobsLoading(false));
  }
}

function* createJobSaga(action) {
  const { formData, cb } = action.payload;
  console.log('createJobSaga', formData);

  try {
    //yield axios.post(`${config.api_users}`, data, { headers: authHeader()})
    //yield apiJobsService.createJobUsingPOST1
    //yield axios.post(`${config.api_jobs}/file`, formData, {
    yield axios.post(`${config.api_url}/v2/job/file`, formData, {
      headers: {
        ...authHeader(),
        'content-type': 'multipart/form-data'
      }
    });
    cb(true);
    yield put(JobsAction.setJobFilter({page: 0}));
    yield put(RootScopeAction.showNotification({
      type: "success",
      duration: 5000,
      text: i18next.t("Create Job"),
      description: i18next.t("The job has been created successfully and added to the list")
    }));
  } catch (e) {
    cb(false);
    yield put(RootScopeAction.handleAPIError(e));
  }
}

function* createJobRangeSaga(action) {
  const { data, cb } = action.payload;
  console.log('createJobRangeSaga', data);

  try {
    yield apiJobsService.createJob(data);
    cb(true);
    //yield put(JobsAction.getJobsList({}));
    yield put(JobsAction.setJobFilter({page: 0}));
    yield put(RootScopeAction.showNotification({
      type: "success",
      duration: 5000,
      text: i18next.t("Create Job"),
      description: i18next.t("Job created successfully and added to the queue")
    }));
  } catch (e) {
    cb(false);
    yield put(RootScopeAction.handleAPIError(e));
  }
}

function* updateJobSaga(action) {
  const { id, data, cb } = action.payload;
  console.log('updateJobSaga PUT', data);

  try {
    const {data: putData} = yield apiJobsService.updateJobTypeOrIteration(id, data);
    cb(putData);
    yield put(JobsAction.setJobFilter({page: 0}));
    yield put(RootScopeAction.showNotification({
      type: "success",
      duration: 5000,
      text: i18next.t("Update Job"),
      description: i18next.t("Job updated successfully")
    }));
  } catch (e) {
    cb(false);
    yield put(RootScopeAction.handleAPIError(e));
  }
}

function* mergeJobSaga(action) {
  const { mergeData, cb } = action.payload;
  console.log('mergeJobSaga', mergeData);

  try {
    const {data} = yield apiJobsService.mergeJob(mergeData);
    cb(data);
    yield put(JobsAction.setJobFilter({page: 0}));
    yield put(RootScopeAction.showNotification({
      type: "success",
      duration: 5000,
      text: i18next.t("Merge Job"),
      description: i18next.t("Job was merged successfully")
    }));
  } catch (e) {
    cb(false);
    yield put(RootScopeAction.handleAPIError(e));
  }
}

function* setStatusSaga(action) {
  const {jobId, status, callback = null} = action.payload;

  try {
    yield apiJobsService.updateJobStatus(jobId, status);
    yield put(JobsAction.setJobStatus(jobId, status));
    if (callback) callback();
  } catch (e) {
    console.log('E', e)
    yield put(RootScopeAction.handleAPIError(e));
  }
}

function* setSingleJobStatusSaga(action) {
  const {jobId, status, callback = null} = action.payload;

  try {
    yield apiJobsService.updateJobStatus(jobId, status);
    yield put(JobsAction.setSingleJobStatus(jobId, status));
    if (callback) callback();
  } catch (e) {
    yield put(RootScopeAction.handleAPIError(e));
  }
}

function* getJobSaga(action) {
  yield put(JobsAction.setJobsLoading(true));
  try {
    const {data: currentJob} = yield apiJobsService.getJob(action.payload);
    yield put(JobsAction.updateJobsState({currentJob}));
  } catch (e) {
    yield put(RootScopeAction.handleAPIError(e));
  }
  yield put(JobsAction.setJobsLoading(false));
}

function* getMainJobSaga() {
  yield put(JobsAction.setJobsLoading(true));
  try {
    const {data: currentJob} = yield apiJobsService.getMainJob();
    yield put(JobsAction.updateJobsState({currentJob}));
  } catch (e) {
    yield put(RootScopeAction.handleAPIError(e));
  }
  yield put(JobsAction.setJobsLoading(false));
}

function* getJobCountries() {
  try {
    const {data: countries} = yield apiStatService.getCountryStat();
    yield put(JobsAction.updateJobsState({countries}));
  } catch (e) {
    yield put(RootScopeAction.handleAPIError(e));
  }
}

function* getJobPhones(action) {
  try {
    console.log('action', action.payload);
    const {jobId, filters: {page = 0, size}, cb} = action.payload;
    const {data} = yield apiJobsService.getJobPhones(jobId, page, size);
    cb(data);
  } catch (e) {

  }
}

/*function* deleteJobSaga(action) {
  const { id } = action.payload;

  try {
    yield axios.delete(`${config.api_users}/${id}`, { headers: authHeader()})
    yield put(UsersAction.getUserList({}));
  } catch (e) {
    yield put(RootScopeAction.showNotification({
      text: e.message,
      type: "error",
      duration: 5000
    }));
  }
}*/

function* setJobFiltersSaga() {
  yield put(JobsAction.getJobsList());
}

function* searchJobsSaga(action) {
  const {search = '', callback} = action.payload;

  try {
    const filtersObject = {
      batch: 15,
      skip: 0,
    }
    if (search) {
      filtersObject.nameOrNumber = search;
    }

    const {data} = yield apiJobsService.getJobs(filtersObject);
    callback(data.content || []);
  } catch (e) {
    yield put(RootScopeAction.handleAPIError(e));
    callback([]);
  }
}

export function* watchJobs() {
  yield takeEvery(JobsAction.GET_JOBS_LIST, getJobsListSaga);
  yield takeEvery(JobsAction.CREATE_JOB, createJobSaga);
  yield takeEvery(JobsAction.CREATE_JOB_RANGE, createJobRangeSaga);
  yield takeEvery(JobsAction.UPDATE_JOB, updateJobSaga);
  yield takeEvery(JobsAction.MERGE_JOB, mergeJobSaga);
  yield takeEvery(JobsAction.CALL_JOB_STATUS, setStatusSaga);
  yield takeEvery(JobsAction.CALL_SINGLE_JOB_STATUS, setSingleJobStatusSaga);
  yield takeEvery(JobsAction.GET_JOB, getJobSaga);
  yield takeEvery(JobsAction.GET_MAIN_JOB, getMainJobSaga);
  yield takeEvery(JobsAction.GET_JOB_COUNTRIES, getJobCountries);
  yield takeEvery(JobsAction.GET_JOB_PHONES, getJobPhones);
  yield takeEvery(JobsAction.SET_JOB_FILTER, setJobFiltersSaga);
  //yield takeEvery(JobsAction.DELETE_JOB, deleteJobSaga);
  yield takeEvery(JobsAction.SEARCH_JOBS, searchJobsSaga);
}
