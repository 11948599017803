import React, { memo } from "react";
import { useSelector } from "react-redux";
import { getRootScope } from "../../store/rootScope/rootScopeSelector";
import { AnimatedView } from "../../shared-components/UI/AnimatedView";
import * as Styled from "./styled";

const LoginLayout = memo((props) => {
  const { dataLoading } = useSelector((state) =>
    getRootScope(state)
  );

  return (
    <>
      <Styled.Content>
        <div>
          <Styled.Container>{props.children}</Styled.Container>
        </div>
      </Styled.Content>
      {dataLoading && <AnimatedView inProp={dataLoading} />}
    </>
  );
});

export default LoginLayout;
