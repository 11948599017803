import React from 'react';
import {Page, Text, View, Document, StyleSheet, Font, Image, Link} from '@react-pdf/renderer';
import {withTranslation} from "react-i18next";
import font from "../../../../assets/fonts/arial-unicode-ms.ttf";
import {SupportService} from "../../../support";
import {EXT_TITLE, VERSION} from "../../../../config/consts";
import {imageByIndex} from "../../../../helpers/imageByIndex";
import moment from "moment";
import {capitalize} from "../../../../helpers/capitalize";

Font.register({
  family: 'pdfFont',
  src: font
});
Font.registerEmojiSource({
  format: 'png',
  url: 'https://twemoji.maxcdn.com/2/72x72/',
});

const SocialIcon = (network) => {
  const images = require.context('../../../../assets/img/social', true);
  try {
    let img = images('./' + network.toLowerCase() + '.png');
    return (
      <Image style={{width: 10, height: 10, verticalAlign: 'middle'}} key={network + Math.random()} alt={network}
             title={network} src={img}/>
    );
  } catch (e) {
    return <Text> </Text>;
  }
};
const SocialInfo = ({title, info}) => {
  return <View style={{display: 'table'}}>
    <View style={{margin: "auto", flexDirection: "row"}}>
      <View style={{width: '30%'}}>
        <Text style={{...styles.sourceText, textAlign: 'left'}}>{title}</Text>
      </View>
      <View style={{width: '70%'}}>
        <Text style={{...styles.sourceText, textAlign: 'left'}}>{info || '-'}</Text>
      </View>
    </View>
  </View>
};

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'pdfFont',
    marginBottom: 10,
  },
  phone: {
    fontSize: 10,
    textAlign: 'center',
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 14,
    margin: 12,
    fontFamily: 'pdfFont'
  },
  text: {
    margin: 12,
    fontSize: 12,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
  sourceText: {
    margin: 5,
    fontSize: 10,
    textAlign: 'justify',
    fontFamily: 'pdfFont',
  },
  row: {
    margin: "auto",
    flexDirection: "row",
    textAlign: 'left',
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'white',
    backgroundColor: '#3994DB',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 40,
    padding: 13
  },
  headerPadding: {
    height: 20,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  link: {
    textDecoration: 'none',
    verticalAlign: 'middle',
    fontSize: 12,
  },
  sourceTitle: {
    fontWeight: 600,
    color: '#666',
    padding: '0.5em 1.5em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: 0.5,
    marginTop: 15,
    marginBottom: 10,
  },
  sourceSubTitle: {
    fontSize: 12,
    fontWeight: 300,
    color: '#666',
    padding: '0.5em 1.5em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: 0.5,
    marginTop: 15,
    marginBottom: 10,
  },
  column50: {
    width: '50%'
  },
  flexBox: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  sourceImage: {
    width: 100,
    height: 100,
    objectFit: 'cover',
    marginRight: 3,
    marginBottom: 3
  }
});

const ExportPdfProfile = ({t, data}) => {
  console.log('ExportPdfProfiles.data', data);
  const {target, sources = []} = data;
  const {id, images = [], names, phone, socialNetworks = []} = target;
  const [image] = images;
  const [name] = names;
  sources.map(({lmSource, profile}) => {
    profile.socialMap = [];
    if (lmSource === 'DEEPWEB' && profile.other) {
      for (const [socialNetwork, entries = [] ] of Object.entries(profile.other)) {
        const socialMap = [];
        entries.map(entry => {
          socialMap.push({...entry, socialNetwork, phone})
        })
        profile.socialMap = socialMap;
      }
    }
  })

  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.header} fixed>{t("Created by")} {EXT_TITLE} {VERSION}</Text>
        <View style={styles.headerPadding} fixed/>
        <View style={{marginTop: "100px"}}>
          {
            image && <Image
              style={styles.image}
              src={imageByIndex(image)}
            />
          }
          <Text style={styles.title}>{name}</Text>
          <Text style={styles.phone}>{phone}</Text>
          {
            socialNetworks.map(({id, socialNetwork}, i) => {
              return <Text style={styles.phone} key={i + 'socText'}>
                <Link style={styles.link} target="_blank" src={SupportService.getSocialUrl(socialNetwork, id)}
                      key={i}>{SocialIcon(socialNetwork)} {id}</Link>
              </Text>;
            })
          }
        </View>
        <Text break/>
        {
          sources.map((source, i) => {
            return <View key={i}>
              <Text
                style={styles.sourceTitle}>{SocialIcon(source.lmSource.toLowerCase())} {capitalize(source.lmSource)}
              </Text>
              <View style={{display: 'table'}}>
                <View style={styles.row}>
                  <View style={styles.column50}>
                    <SocialInfo title={t('Country')} info={source?.profile?.country}/>
                    <SocialInfo title={t('City')} info={source?.profile?.city}/>
                    {/*<SocialInfo title={t('ID')} info={source?.profile?.id} />*/}
                    <SocialInfo title={t('Created')}
                                info={moment(source?.profile?.createdTimestamp)?.format("Y.MM.DD HH:mm:ss")}/>
                  </View>
                  <View style={styles.column50}>
                    <SocialInfo title={t('Names')} info={source?.profile?.names?.join(', ')}/>
                    <SocialInfo title={t('Emails')} info={source?.profile?.emails?.join(', ')}/>
                    <SocialInfo title={t('Phone')} info={source?.profile?.phone}/>
                    <SocialInfo title={t('Last Online')}
                                info={moment(source?.profile?.lastOnline, 'x', true).isValid() ? moment(source?.profile?.lastOnline, 'x').format("Y.MM.DD HH:mm:ss") : source?.profile?.lastOnline}/>
                  </View>
                </View>
              </View>
              {
                source?.profile?.socialMap?.map((searchRes, k) =>
                  <View key={k}>
                    <Text
                      style={styles.sourceSubTitle}>{SocialIcon(searchRes.socialNetwork.toLowerCase())} {capitalize(searchRes.socialNetwork)} : {searchRes.socialNetworkId}
                    </Text>
                    <View style={{display: 'table'}}>
                      <View style={styles.row}>
                        <View style={styles.column50}>
                          <SocialInfo title={t('First Name')} info={searchRes?.firstName}/>
                          <SocialInfo title={t('Last Name')} info={searchRes?.lastNames?.join(', ')}/>
                          <SocialInfo title={t('Maiden Name')} info={searchRes?.maidenName}/>
                          <SocialInfo title={t('Emails')} info={searchRes?.emails?.join(', ')}/>
                          <SocialInfo title={t('Phones')} info={searchRes?.phones?.join(', ')}/>
                          <SocialInfo title={t('Addresses')} info={searchRes?.addresses?.join(', ')}/>
                          <SocialInfo title={t('Additional Contact')} info={searchRes?.additionalContact}/>
                          <SocialInfo title={t('Site')} info={searchRes?.site && <Link style={{...styles.link, fontSize: 10}} target="_blank" src={searchRes.site} key={searchRes.site}>{searchRes.site}</Link>}/>
                          <SocialInfo title={t('Birth Date')} info={searchRes?.birthday}/>
                          <SocialInfo title={t('Gender')} info={searchRes?.gender}/>
                          <SocialInfo title={t('Married')} info={searchRes?.married}/>
                        </View>
                        <View style={styles.column50}>
                          <SocialInfo title={t('Religion')} info={searchRes?.religion}/>
                          <SocialInfo title={t('Political')} info={searchRes?.political}/>
                          <SocialInfo title={t('Friends')} info={searchRes?.friends}/>
                          <SocialInfo title={t('About')} info={searchRes?.about}/>
                          <SocialInfo title={t('University')} info={searchRes?.university}/>
                          <SocialInfo title={t('Faculty')} info={searchRes?.faculty}/>
                          <SocialInfo title={t('Chair')} info={searchRes?.chair}/>
                          <SocialInfo title={t('University Year End')} info={searchRes?.universityYearEnd}/>
                          <SocialInfo title={t('Work')} info={searchRes?.work}/>
                          <SocialInfo title={t('Schools')} info={searchRes?.schools}/>
                          <SocialInfo title={''} info={''}/>
                        </View>
                      </View>
                    </View>
                  </View>
                )
              }
              <View style={{margin: 20}}>
                {
                  source?.profile?.socialNetworks?.map(({id, socialNetwork}) => {
                    return <Link
                      style={{...styles.link, fontSize: 10}}
                      target="_blank"
                      src={SupportService.getSocialUrl(socialNetwork, id)}
                      key={id}
                    >{SocialIcon(socialNetwork)} {id}</Link>
                  })
                }
              </View>
              <View style={styles.flexBox}>
                {source?.profile?.photos?.map((photo, i) => {
                  return <Link key={i} target={"_blank"} src={imageByIndex(photo)}>
                    <Image style={styles.sourceImage} key={i} src={imageByIndex(photo)}/>
                  </Link>;
                })}
              </View>
              <View style={{marginBottom: 50}}/>
            </View>
          })
        }
        <Text style={styles.pageNumber} render={({pageNumber, totalPages}) => (
          `${pageNumber} / ${totalPages}`
        )} fixed/>
      </Page>
    </Document>
  )
};


export default withTranslation()(ExportPdfProfile);