import styled from "styled-components";
import { themes } from "../../../../config/Themes";

export const CurrentPage = styled.h2`
  font-size: 18px;
  color: ${themes.regular.white};
  margin: 0 auto 0 0;

  @media (min-width: 1280px) {
    display: none;
  }
`;