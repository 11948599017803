import React from "react";
import * as Styled from "./styled";
import IntLogo from "./IntLogo";
import IntMenu from "./IntMenu";
import { IntUtilityButtons } from "./IntUtilityButtons";
import IntUserMenu from "./IntUserMenu";
import IntBurgerMenu from "./IntBurgerMenu";

export default () => (
  <Styled.Header id="topnav">
    <Styled.HeaderMenu>
      <IntBurgerMenu />
      <IntLogo />
      <IntMenu />
      <IntUtilityButtons />
      <IntUserMenu />
    </Styled.HeaderMenu>
  </Styled.Header>
);
