import React from "react";
import Button from "../../shared-components/Buttons/Buttons";

import * as Styled from "./styled";
import {withTranslation} from "react-i18next";

const NotFoundPage = ({t}) => {
  return (
    <Styled.Container>
      <h2>{t('404 Not Found')}</h2>
      <p>{t("An error has occurred. Requested page cannot be found!")}</p>
      <Button type="confirm">
        <Styled.HomeLink to="/">{t("Go to Homepage")}</Styled.HomeLink>
      </Button>
    </Styled.Container>
  );
};

export default withTranslation()(NotFoundPage);