import BaseReducer from "../BaseReducer";
import UserSettingsAction from "./userSettingsAction";

export default class UserSettingsReducer extends BaseReducer {
  initialState = {
    chosenLang: localStorage.getItem("webint_lang"),
    activeRoute: {},
  };

  [UserSettingsAction.SET_LANGUAGE](state, action) {
    return {
      ...state,
      chosenLang: action.payload,
    };
  }

  [UserSettingsAction.SET_ACTIVE_ROUTE](state, action) {
    return {
      ...state,
      activeRoute: action.payload,
    };
  }
}
