import BaseReducer from "../BaseReducer";
import ProfilesAction from "./profilesAction";
import config from "../../config/config";

export default class ProfilesReducer extends BaseReducer {
  initialState = {
    profiles: [],
    filters: {
      page: 0,
      size: config.profilesPageSize,
    },
    filterFiles: {},
    filterLists: {},
    isFiltered: false,
    imageMode: false,
    filterListMode: false,
    loading: true,
    currentProfile: null,
    loadingCurrentProfile: false,

    currentImage: null,
    currentImageFile: null,
    currentImageLoading: false,
    currentImageResults: null,
    //imageProfiles: [],
    imageSearchDone: false,
    exportProfiles: [],
    exportLoading: false,
    selectedPhones: [],
    selectFiltersMode: false,
    selectedFilters: [],
  };

  [ProfilesAction.SET_PROFILES_LIST](state, action) {
    return {
      ...state,
      profiles: action.payload.data
    };
  }

  [ProfilesAction.SET_PROFILES_LOADING](state, action) {
    return {
      ...state,
      loading: action.payload,
    };
  }

  [ProfilesAction.SET_PROFILES_FILTER](state, action) {
    return {
      ...state,
      filters: {
        ...state.filters,
        ...action.payload
      },
    };
  }

  [ProfilesAction.CLEAR_PROFILES_FILTER](state) {
    return {
      /*...state,
      filters: this.initialState.filters,
      imageMode: this.initialState.imageMode,
      selectedFilters: this.initialState.selectedFilters,
      selectFiltersMode: this.initialState.selectFiltersMode,
      currentImage: this.initialState.currentImage,
      currentImageFile: this.initialState.currentImageFile,
      currentImageResults: this.initialState.currentImageResults,*/
      ...this.initialState
    };
  }

  [ProfilesAction.UPDATE_PROFILES_STATE](state, action) {
    return {
      ...state,
      ...action.payload,
    }
  }

  [ProfilesAction.SET_SELECT_PHONES](state, action) {
    const {phones, selected} = action.payload;
    if (selected) {
      return {
        ...state,
        selectedPhones: [
          ...state.selectedPhones,
          ...phones.filter(phone => !state.selectedPhones.includes(phone)),
        ],
      }
    } else {
      return {
        ...state,
        selectedPhones: state.selectedPhones.filter(phone => !phones.includes(phone)),
      }
    }

  }

  [ProfilesAction.CLEAR_SELECT_PHONES](state) {
    return {
      ...state,
      selectedPhones: [],
    }
  }

  [ProfilesAction.SET_SELECT_FILTERS_MODE](state, action) {
    return {
      ...state,
      selectFiltersMode: action.payload,
    }
  }

  [ProfilesAction.SET_SELECT_FILTERS](state, action) {
    const {filters, selected} = action.payload;
    if (selected) {
      return {
        ...state,
        selectedFilters: [
          ...state.selectedFilters,
          ...filters.filter(filter => !state.selectedFilters.includes(filter)),
        ],
      }
    } else {
      return {
        ...state,
        selectedFilters: state.selectedFilters.filter(filter => !filters.includes(filter)),
      }
    }
  }

  [ProfilesAction.CLEAR_SELECT_FILTERS](state) {
    return {
      ...state,
      selectedFilters: [],
    }
  }

  [ProfilesAction.EXPORT_PROFILES_LOADING](state, action) {
    return {
      ...state,
      exportLoading: action.payload,
    }
  }

  [ProfilesAction.SET_FILTER_FILE](state, action) {
    const {field, fileObj} = action.payload;
    return {
      ...state,
      filterFiles: {
        ...state.filterFiles,
        [field]: fileObj,
      },
    }
  }

  [ProfilesAction.REMOVE_FILTER_FILE](state, action) {
    let {[action.payload]: omit, ...rest} = state.filterFiles;
    return {
      ...state,
      filterFiles: {...rest},
    };
  }

  [ProfilesAction.CLEAR_FILTER_FILES](state) {
    return {
      ...state,
      filterFiles: {},
      imageMode: false,
      currentImage: null,
      currentImageLoading: false,
      currentImageFile: null,
      imageSearchDone: false,
    }
  }

  [ProfilesAction.SET_FILTER_LIST](state, action) {
    const {field, listObj} = action.payload;
    return {
      ...state,
      filterLists: {
        [field]: listObj,
      },
    }
  }

  [ProfilesAction.REMOVE_FILTER_LIST](state, action) {
    let {[action.payload]: omit, ...rest} = state.filterLists;
    return {
      ...state,
      filterLists: {...rest},
    };
  }

  [ProfilesAction.CLEAR_FILTER_LISTS](state) {
    return {
      ...state,
      filterLists: {},
    }
  }
}
