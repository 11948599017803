import React from "react";
import ExportPdfProfiles from "./ExportPdfProfiles";
import {pdf} from '@react-pdf/renderer';
import moment from "moment";
import ExportPdfProfile from "./ExportPdfProfile";
import ExportPdfAudit from "./ExportPdfAudit";

class ExportPdf {
  constructor(data) {
    this.data = [];
    this.rawData = Array.isArray(data) ? [...data] : {...data};
    this.fileName = "PROFILES_" + moment().format("YYYY_MM_DD_HH_mm_ss");
  }
  prepareProfileList() {
    this.data = <ExportPdfProfiles data={this.rawData} />;
    return this;
  }
  prepareProfile() {
    const {target} = this.rawData;
    this.fileName = "PROFILE_" + target?.phone + '_' + moment().format("YYYY_MM_DD_HH_mm_ss");
    this.data = <ExportPdfProfile data={this.rawData} />;
    return this;
  }
  prepareAuditList() {
    this.data = <ExportPdfAudit data={this.rawData} />
    this.fileName = "AUDIT_" + moment().format("YYYY_MM_DD_HH_mm_ss");

    return this;
  }

  export() {
    this.downloadBlob(pdf(this.data).toBlob());
    return this;
  }

  //other methods
  downloadBlob(promise) {
    promise.then(blob => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = this.fileName + '.pdf';
      document.body.append(link);
      link.click();
      link.remove();
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    })
  }
}

export default ExportPdf