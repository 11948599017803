import React, { useState } from "react";
import SideMenu from "../../SideMenu";

import * as Styled from "./styled";

export default () => {
  const [isShownSideMenu, setShownSideMenu] = useState(false);
  const openSideMenu = () => setShownSideMenu(true);
  const closeSideMenu = () => setShownSideMenu(false);

  return (
    <>
      <Styled.BurgerButton onClick={openSideMenu} />
      <SideMenu isShown={isShownSideMenu} clickedBackDrop={closeSideMenu} />
    </>
  );
};
