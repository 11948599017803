import React, { memo, useCallback } from "react";
import { Transition } from "react-transition-group";
import { useDispatch, useSelector } from "react-redux";
import PopupAction from "../../../store/popup/popupAction";

import * as Styled from "./styled";

export default memo(() => {
  const isShown = useSelector((state) => state.popup.isPopupShown);
  const content = useSelector((state) => state.popup.popupContent);

  const dispatch = useDispatch();
  const closePopup = useCallback(() => dispatch(PopupAction.closePopup()), [
    dispatch,
  ]);

  const handleBackdropClick = (e) => closePopup();
  return (
    <Transition in={isShown} timeout={duration} mountOnEnter unmountOnExit>
      {(state) => (
        <Styled.Backdrop
          onMouseDown={handleBackdropClick}
          style={{
            ...defaultBackdropStyle,
            ...transitionBackdropStyles[state],
          }}
        >
          <Styled.Container
            onMouseDown={(e) => e.stopPropagation()}
            style={{
              ...defaultContainerStyle,
              ...transitionContainerStyles[state],
            }}
          >
            {content}
          </Styled.Container>
        </Styled.Backdrop>
      )}
    </Transition>
  );
});

const duration = {
  appear: 0,
  enter: 0,
  exit: 120,
};
const defaultBackdropStyle = {
  opacity: 0,
  transition: `all ${duration}ms ease-out`,
};
const transitionBackdropStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0 },
};

const defaultContainerStyle = {
  transform: "translate(-50%, -50%)",
  opacity: 0,
  transition: "all 320ms ease-out",
};
const transitionContainerStyles = {
  entering: { transform: "translate(0, -30%)", opacity: 0 },
  entered: { transform: "translate(0, 0)", opacity: 1 },
  exiting: { transform: "translate(0, 0)", opacity: 1 },
  exited: { transform: "translate(0, -30%)", opacity: 1 },
};
