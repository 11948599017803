import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { themes } from "../../../../config/Themes";

export const HeaderNavMenu = styled.div`
  height: 56px;
  margin-right: auto;
  flex: 1 0 auto;

  @media (max-width: ${themes.regular.wide_screen}) {
    display: none;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  flex-flow: row;
  height: 100%;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;

  @media (max-width: ${themes.regular.wide_screen}) {
    flex-flow: column;
  }
`;

export const NavMenuItem = styled.li`
  height: 100%;
  margin: 0;
  padding: 0 3em;
  min-width: fit-content;
  position: relative;

  span:hover {
    color: ${themes.regular.white};
  }

  @media (max-width: ${themes.regular.wide_screen}) {
    display: flex;
    align-items: center;
    border: 0;
    color: ${themes.regular.medium_grey};
    margin: 0;
    padding: 15px;

    :hover {
      border: 0;

      a {
        color: ${themes.regular.blue_bg};
      }
    }
  }

  ${props =>
    props.active &&
    css`
      a {
        border-top: 3px solid ${themes.regular.white};
      }
    `}
`;

export const MenuLink = styled(Link)`
  color: ${themes.regular.white};
  font-family: "Open Sans", sans-serif;
  font-size: 1em;
  font-weight: 400;
  line-height: 53px;
  width: 100%;
  height: 100%;
  display: block;
  border-top: 3px solid transparent;

  :hover {
    color: ${themes.regular.white};
    border-top: 3px solid ${themes.regular.white};
  }
`;
