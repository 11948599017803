import React, { memo } from "react";
import ExtHeader from "./ExtHeader";
import ExtFooter from "./ExtFooter";

import * as Styled from "./styled";

const ExtLayout = memo(({ children }) => {
  return (
    <>
      <Styled.Container>
          <ExtHeader />
          <Styled.Content>
              <Styled.Wrapper>{children}</Styled.Wrapper>
          </Styled.Content>
          <ExtFooter />
      </Styled.Container>
    </>
  );
});

export default ExtLayout;
