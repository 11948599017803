import React, { memo, forwardRef } from "react";
import { Button, Chart } from "./styled";
import Tooltip from "../../UI/Tooltip";

//available types are:  CONFIRM || CANCEL || BORDERED || REFRESH || EXPAND || SORT || LOGOUT || ARCHIVED ||
//                      USERS-SEARCH || USERS-EDIT || USERS-DELETE || EDIT-QUOTA || ASSIGN || CHART || EXPORT || DELETE-CASE

export default memo(forwardRef(({
  id,
  name,
  type,
  clicked,
  isExpanded,
  isRefreshing,
  sorted,
  children,
  active,
  title,
  dataTip,
  disabled
}, ref) => (
  <>
    {/*<Tooltip id="button" />*/}
    <Button
      id={id}
      name={name}
      type={type}
      onClick={clicked}
      isExpanded={isExpanded}
      isRefreshing={isRefreshing}
      sorted={sorted}
      active={active}
      title={title}
      data-tip={dataTip}
      disabled={disabled}
      ref={ref}
    >
      {type === "chart" && <Chart />}
      {children}
    </Button>
  </>
)));
