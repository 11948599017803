import React, { memo } from "react";
import { Transition } from "react-transition-group";
import LoadingImg from "../../../assets/img/loading.png";
import styled, { keyframes } from "styled-components";

export const AnimatedView = memo(({ inProp, children }) => {
  return (
    <Transition
      in={inProp}
      timeout={0}
      mountOnEnter
      unmountOnExit
    >
      {(state) => (
        <AnimatedCover>
          {children}
          <Loading />
        </AnimatedCover>
      )}
    </Transition>
  );
});

const AnimatedCover = styled.div`
  position: absolute;
  z-index: 2100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(1px);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const spin = keyframes`
100% {
  transform: rotate(-360deg);
}
`;

const Loading = styled.div`
  width: 50px;
  height: 50px;
  background: url(${LoadingImg}) no-repeat center center;
  background-size: cover;
  animation: 2s ${spin} infinite linear;
`;
