import BaseReducer from "../BaseReducer";
import RootScopeAction from "./rootScopeAction";

export default class RootScopeReducer extends BaseReducer {
  initialState = {
    notification: [],
    globals: {},
    currentUser: {},
    dataLoading: false,
  };

  [RootScopeAction.UPDATE_STATE](state, action) {
    return {
      ...state,
      ...action.payload,
    };
  }

  [RootScopeAction.SHOW_NOTIFICATION](state, action) {
    return {
      ...state,
      notification: state.notification.concat(action.payload),
    };
  }

  [RootScopeAction.CLEAR_NOTIFICATIONS](state, action) {
    return {
      ...state,
      notification: [],
    };
  }

  [RootScopeAction.CHECK_403](state, action) {
    if (action.payload === 403) {
      return {
        ...state,
        notification: {
          text: "Session timeout. Please refresh the page and try again",
          type: "alert",
          duration: 5000,
        },
      };
    } else {
      return state;
    }
  }
  /*[RootScopeAction.HANDLE_API_ERROR](state, action) {
    console.log('RootScopeAction.HANDLE_API_ERROR', state.router);
    return state;
  }*/
}
