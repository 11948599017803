import { put, takeEvery, delay } from "redux-saga/effects";
import RootScopeAction from "../rootScope/rootScopeAction";
import AuthAction from "../auth/authAction";

function* showNotificationSaga(action) {
  //const { duration } = action.payload;
  //yield delay(duration);
  //yield put(RootScopeAction.clearNotifications());
}

function* handleAPIErrorSaga(action) {
  console.log('action.payload', action.payload.message);
  let {message, request, response} = action.payload;
  let dataMessage;
  if (response) {
    const { data, status} = response;
    if (data && data.message) {
      dataMessage = data.message;
    }
    if (status === 401) {
      yield put(AuthAction.logout({}));
    }
  }

  const toast = dataMessage || message;
  yield put(RootScopeAction.showNotification({
    text: toast,
    type: "error",
    duration: 5000
  }));



}

export function* watchRootScope() {
  yield takeEvery(RootScopeAction.SHOW_NOTIFICATION, showNotificationSaga);
  yield takeEvery(RootScopeAction.HANDLE_API_ERROR, handleAPIErrorSaga);
}