import React, { memo } from "react";
import styled from "styled-components";

export const ErrorBoundaryCover = memo(({ error, component }) => {
  console.log(error);
  const createMarkup = (content) => {
    return { __html: content };
  };
  return (
    <Cover>
      <p>Something went wrong in {component}</p>
      {error && (
        <p dangerouslySetInnerHTML={createMarkup(error.componentStack)} />
      )}
      <p>Please try to reload the page</p>
    </Cover>
  );
});

const Cover = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    text-align: center;
    padding: 0 30px;
  }
`;
