import styled, { css } from "styled-components";
import {themes} from "../../../config/Themes";

export const SelectLanguage = styled.div`
  width: 100%;
  border-bottom: 1px solid ${themes.regular.blue_bg};
  display: flex;
  flex-flow: column;
  margin-bottom: 1em;

  span {
    color: ${themes.regular.white};
    font-size: 0.85em;
    padding-left: 15px;
  }
`;

export const LangOptions = styled.div`
  padding: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const Option = styled.img`
  width: auto;
  height: 15px;
  object-fit: cover;
  object-position: center center;

  :hover {
    cursor: pointer;
    box-shadow: 0px 0px 9px ${themes.regular.white},
                0px 0px 12px ${themes.regular.report_text};
  }

  ${props => props.chosen && css`
    width: 20px;
    height: 20px;
    transform: scale(1.2);
    border-radius: 50%;
    border: 1px solid ${themes.regular.yellow};
  `}
`;