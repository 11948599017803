import moment from "moment";
import i18n from "i18next";
import {SupportService} from "../../support";
import {imageByIndex} from "../../../helpers/imageByIndex";

class ExportTxt {
  constructor(data) {
    this.data = [];
    this.rawData = Array.isArray(data) ? [...data] : {...data};
    this.fileName = 'export'
  }
  prepareProfileList() {
    this.fileName = "PROFILES_" + moment().format("YYYY_MM_DD_HH_mm_ss");
    this.data.push([
      i18n.t('Phone'),
      i18n.t('Name'),
      i18n.t('Online'),
      i18n.t('Networks'),
      i18n.t('Photos'),
      i18n.t('Sources')
    ].join(";"));
    this.rawData.map(row => {
      this.data.push([
        row.phone,
        row.names?.[0],
        row.sources.length ? "+" : "-",
        row.socialNetworks.map(s => SupportService.getSocialUrl(s.socialNetwork, s.id)).join(", "),
        row.images.map(image => imageByIndex(image)).join(","),
        row.sources.join(", ")
      ].join(";"))
    })
    return this;
  }
  prepareProfile() {
    const {target, sources} = this.rawData;
    this.fileName = "PROFILE_" + target?.phone + '_' + moment().format("YYYY_MM_DD_HH_mm_ss");

    this.data.push([
      i18n.t('Source'),
      i18n.t('Phone'),
      i18n.t('Created'),
      i18n.t('Names'),
      i18n.t('Profiles'),
      i18n.t('Photos'),
      i18n.t('Emails'),
      i18n.t('Country'),
      i18n.t('City'),
      i18n.t('Last Online'),
    ].join(";"));
    sources.map(row => {
      this.data.push([
        row.lmSource,
        row.profile?.phone,
        moment(row.profile?.createdTimestamp).format("YYYY/MM/DD HH:mm:ss"),
        row.profile?.names?.join(', '),
        row.profile?.socialNetworks?.map(s => SupportService.getSocialUrl(s.socialNetwork, s.id))?.join(", "),
        row.profile?.photos?.map(image => imageByIndex(image))?.join(","),
        row.profile?.emails?.join(", "),
        row.profile?.country,
        row.profile?.city,
        moment(row.profile.lastOnline, 'x', true).isValid()
          ? moment(row.profile.lastOnline, 'x').format("Y.MM.DD HH:mm:ss")
          : row.profile.lastOnline
      ].join(";"))
    });

    //console.log('sources', sources);

    return this;
  }
  prepareData(data) {
    let newDate = [];
    Object.values(data).map(item => {
      let socialNetworks = [];
      item.socialNetworks.map(socItem => socialNetworks.push(socItem.socialNetwork));

      newDate.push(`${item.phone} - ${item.names.join(', ')} - ${socialNetworks.join(', ')} - ${item.sources.join(', ')}`)
    })
    return newDate;
  }
  export() {
    const url = window.URL.createObjectURL(new Blob([this.data.join('\n')], {type: 'text/plain'}));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', this.fileName + ".txt");
    document.body.appendChild(link);
    link.click();

    return this;
  }
}

export default ExportTxt