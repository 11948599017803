import styled from "styled-components";
import { themes } from "../../../config/Themes";

export const Backdrop = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: rgba(0, 0, 0, .3);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  /*max-height: 100vh;*/
  border-radius: 12px;
  background: ${themes.regular.white};
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.2), 
                0px 3px 16px rgba(0, 0, 0, 0.12),
                0px 9px 12px rgba(0, 0, 0, 0.14);
  display: flex;
  flex-flow: column;
`;