import styled, { css } from "styled-components";
import { themes } from "../../../config/Themes";
import { Link } from "react-router-dom";
import Users from "../../../assets/img/star.png";
import Avatars from "../../../assets/img/avatars.png";
import Audit from "../../../assets/img/audit-trail.png";
import LogoutIcon from "../../../assets/img/exit_white.png";

export const Container = styled.div`
  background-color: ${themes.regular.light_blue_bg};
  border-radius: 5px;
  margin: 0;
  padding: 1.5em;
  box-sizing: border-box;
  position: absolute;
  right: 0;
  top: 56px;
  width: 215px;
  z-index: 1000;
  display: flex;
  flex-flow: column;

  @media (max-width: ${themes.regular.wide_screen}) {
    border-radius: 0;
    position: static;
    width: 100%;
  }
`;

export const UserLink = styled(Link)`
  color: ${themes.regular.white};
  font-size: 0.85em;
  padding: 10px 0 10px 40px;

  ${props => props.name === "users" && css`
    background: url(${Users}) no-repeat left center;
  `}

  ${props => props.name === "avatars" && css`
    background: url(${Avatars}) no-repeat left center;
  `}

  ${props => props.name === "audit" && css`
    background: url(${Audit}) no-repeat left center;
  `}

  ${props => props.name === "logout" && css`
    background: url(${LogoutIcon}) no-repeat left center;
  `}
`;

export const Logout = styled.span`
  color: ${themes.regular.white};
  font-size: 0.85em;
  padding: 10px 0 10px 40px;
  margin: 0;
  background: url(${LogoutIcon}) no-repeat left center;
  transition: all 250ms ease;

  :hover {
    cursor: pointer;
    color: ${themes.regular.dark_blue};
  }
`

// .user-menu {
//   display: none;
//   font-family: 'Open Sans', sans-serif;
// }
// .user-menu__list {
  
// }
// @media screen and (max-width: 1279px) {
//   .user-menu__list {
//       border-radius: 0;
//       margin: 0;
//       position: static;
//       width: 100%;
//   }
// }

// @media screen and (max-width: 1279px) {
//   .user-menu__item {
//       padding: 1em 0;
//   }
//   .user-menu__item:last-child {
//       display: none;
//   }
// }
