import React from "react";
import Usermenu from "../../../shared-components/UI/UserMenu";
import { ROUTES } from "../../../config/Routes";
import { withRouter } from "react-router-dom";
import { Transition } from "react-transition-group";
import NoAvatar from "../../../assets/img/noavatar.png";
import HomeIcon from "@material-ui/icons/Home";
import ScheduleIcon from '@material-ui/icons/Schedule';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

import * as Styled from "./styled";
import {useSelector} from "react-redux";
import {getCurrentUser} from "../../../store/rootScope/rootScopeSelector";

const defaultStyle = {
  opacity: 0,
  // transform: "scaleX(0)",
  // transformOrigin: "left center",
  transition: "all 320ms ease",
};
const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const defaultMenu = {
  transform: "scaleX(0)",
  transformOrigin: "left center",
  transition: "all 320ms ease",
};
const transitionMenu = {
  entering: { transform: "scaleX(0)" },
  entered: { transform: "scaleX(1)" },
  exiting: { transform: "scaleX(0)" },
  exited: { transform: "scaleX(0)" },
};

export default withRouter(({ match, isShown, clickedBackDrop }) => {
  const {username, roles} = useSelector((state) => getCurrentUser(state));
  return (
    <Transition in={isShown} timeout={320} mountOnEnter unmountOnExit>
      {(state) => (
        <Styled.BackDrop
          onClick={clickedBackDrop}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          <Styled.Container
            style={{
              ...defaultMenu,
              ...transitionMenu[state],
            }}
          >
            <Styled.User>
              {/*<Styled.UserImage src={NoAvatar} alt="User avatar" />*/}
              <Styled.HeaderUserImg />
              <Styled.UserName>{username}</Styled.UserName>
            </Styled.User>
            <Styled.MenuWrapper>
              <Usermenu isShown={true} />
              <Styled.Menu>
                {ROUTES.filter((route) => roles.includes(route.needRole) && route.mainMenu).map(
                  ({ location: { pathname }, name, nameToDisplay }, key) => (
                    <Styled.MenuItem isActive={match.path === pathname} key={key}>
                      {name === "Home" && <HomeIcon />}
                      {name === "Results" && <SupervisorAccountIcon />}
                      {name === "Jobs" && <ScheduleIcon />}
                      <Styled.MenuLink to={pathname}>{nameToDisplay}</Styled.MenuLink>
                    </Styled.MenuItem>
                  )
                )}
              </Styled.Menu>
            </Styled.MenuWrapper>
          </Styled.Container>
        </Styled.BackDrop>
      )}
    </Transition>
  )
});
