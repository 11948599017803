import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font, Image, Link } from '@react-pdf/renderer';
import {withTranslation} from "react-i18next";
import font from "../../../../assets/fonts/arial-unicode-ms.ttf";
import {SupportService} from "../../../support";
import OnlineIcon from "../../../../assets/img/online.png";
import {EXT_TITLE, VERSION} from "../../../../config/consts";

Font.register({
  family: 'Roboto',
  src: font
});
Font.registerEmojiSource({
  format: 'png',
  url: 'https://twemoji.maxcdn.com/2/72x72/',
});

const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'
const COL1_WIDTH = 40
const COLN_WIDTH = (100 - COL1_WIDTH) / 3

const COL_PHONE = 20;
const COL_NAMES = 20;
const COL_PHOTO = 10;
const COL_NETWORKS = 20;
const COL_SOURCES = 30;

const HEADER_STYLES = {
  borderStyle: BORDER_STYLE,
  borderColor: BORDER_COLOR,
  borderBottomColor: '#000',
  borderWidth: 1,
  borderLeftWidth: 0,
  borderTopWidth: 0,
  fontFamily: "Roboto"
}
const COL_STYLES = {
  borderStyle: BORDER_STYLE,
  borderColor: BORDER_COLOR,
  borderWidth: 1,
  borderLeftWidth: 0,
  borderTopWidth: 0,
  fontFamily: "Roboto"
}

const socialIcon = (network) => {
  const images = require.context('../../../../assets/img/social', true);
  try{
    let img = images('./' + network.toLowerCase() + '.png');
    return (
      <>
      <Image style={{margin: 150}} key={network + Math.random()} alt={network} title={network} src={img} />
      <Text> </Text>
      </>
    );
  } catch (e) {
    return <Text> </Text>;
  }
};

const styles = StyleSheet.create({
  body: {
    padding: 30,
    fontSize: 10,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row"
  },
  tableColPhoneHeader: {
    width: COL_PHONE + '%',
    ...HEADER_STYLES
  },
  tableColNamesHeader: {
    width: COL_NAMES + "%",
    ...HEADER_STYLES
  },
  tableColPhotoHeader: {
    width: COL_PHOTO + "%",
    ...HEADER_STYLES
  },
  tableColNetworksHeader: {
    width: COL_NETWORKS + "%",
    ...HEADER_STYLES
  },
  tableColSourcesHeader: {
    width: COL_SOURCES + "%",
    ...HEADER_STYLES
  },
  tableColPhone: {
    width: COL_PHONE + '%',
    ...COL_STYLES
  },
  tableColNames: {
    width: COL_NAMES + '%',
    ...COL_STYLES
  },
  tableColPhoto: {
    width: COL_PHOTO + '%',
    ...COL_STYLES
  },
  tableColNetworks: {
    width: COL_NETWORKS + '%',
    ...COL_STYLES
  },
  tableColSources: {
    width: COL_SOURCES + '%',
    ...COL_STYLES
  },

  tableCellHeader: {
    margin: 5,
    fontSize: 10,
    fontWeight: 500
  },
  tableCell: {
    margin: 5,
    fontSize: 10
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'white',
    backgroundColor: '#3994DB',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 40,
    padding: 13
  },
  headerPadding: {
    height: 20,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

const ExportPdfProfiles = ({t, data}) => {
  console.log('ExportPdfProfiles.data', data);
  return (
  <Document>
    <Page style={styles.body}>
      <Text style={styles.header} fixed>{t("Created by")} {EXT_TITLE} {VERSION}</Text>
      <View style={styles.headerPadding} fixed/>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableColPhoneHeader}>
            <Text style={styles.tableCellHeader}>{t("Phone")}</Text>
          </View>
          <View style={styles.tableColNamesHeader}>
            <Text style={styles.tableCellHeader}>{t("Name")}</Text>
          </View>
          <View style={styles.tableColPhotoHeader}>
            <Text style={styles.tableCellHeader}>{t("Online")}</Text>
          </View>
          <View style={styles.tableColNetworksHeader}>
            <Text style={styles.tableCellHeader}>{t("Networks")}</Text>
          </View>
          <View style={styles.tableColSourcesHeader}>
            <Text style={styles.tableCellHeader}>{t("Sources")}</Text>
          </View>
        </View>
        {
          data.map(profile => (
            <View key={profile.id} style={styles.tableRow}>
              <View style={styles.tableColPhone}>
                <Text style={styles.tableCellHeader}>{profile.phone}</Text>
              </View>
              <View style={styles.tableColNames}>
                <Text style={styles.tableCellHeader}>{profile.names?.[0]}</Text>
              </View>
              <View style={styles.tableColPhoto}>
                <Text style={styles.tableCellHeader}>
                  <Image src={OnlineIcon} style={{width: 8, height: 8}} />
                  {/*{
                    profile.images.length && (<Image scr={globals.api_base_path + imageByIndex(profile.images?.[0])} />)
                  }*/}
                </Text>
              </View>
              <View style={styles.tableColNetworks}>
                <Text style={styles.tableCellHeader}>
                  {profile.socialNetworks && profile.socialNetworks.map(({id, socialNetwork}, i) => {
                    return <Link src={SupportService.getSocialUrl(socialNetwork, id)} key={i}>{socialIcon(socialNetwork)}</Link>;
                  })}
                </Text>
              </View>
              <View style={styles.tableColSources}>
                <Text style={styles.tableCellHeader}>
                  {profile.sources && profile.sources.map((soc, idx) => {
                    return socialIcon(soc);
                  })}
                </Text>
              </View>
            </View>
          ))
        }
      </View>
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed />
    </Page>
  </Document>
)};



export default withTranslation()(ExportPdfProfiles);