import styled from "styled-components";
import UserImg from "../../../../assets/img/user.png";
import { themes } from "../../../../config/Themes";

export const HeaderUserMenu = styled.div`
  position: relative;
  align-items: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0 .5em;

  @media(max-width: ${themes.regular.wide_screen}) {
    display: none;
  }
`; 

export const HeaderUserImg = styled.div`
  background: url(${UserImg}) no-repeat center;
  height: 38px;
  width: 38px;
  margin-right: 10px;
`;

export const UserName = styled.p`
  font-size: 1em;
  color: ${themes.regular.white};
  margin: 0;

  :hover {
    cursor: pointer;
    opacity: .7;
  }
`;
