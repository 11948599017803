import React, { memo, useCallback } from "react";
import {
  useRouteMatch
} from "react-router-dom";
import { Transition } from "react-transition-group";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PopupAction from "../../../store/popup/popupAction";
import { useWindowWidth } from "../../../utils/useWindowWidth";
import { paths } from "../../../config/Routes";

import Create from "../../../assets/img/create_btn.png";
import CreateMobile from "../../../assets/img/add_cases.png";

import { ActionButton } from "./ActionButton";
import Separator from "./Separator";

import * as Styled from "./styled";

export default memo(
  withTranslation()(({ t, isShown, clickedBackdrop }) => {
    const dispatch = useDispatch();
    const showPopup = useCallback(
      (content) => dispatch(PopupAction.showPopup(content)),
      [dispatch]
    );
    const mobile = useWindowWidth() < 1020;

    let headerActionButtons = null;

    if (useRouteMatch(paths.users)) {
      headerActionButtons = (
        <>
          <Separator /><Separator />
        </>
      );
    }

    const defaultStyle = {
      width: 0,
      transition: "all 250ms ease",
    };
    const transitionStyles = {
      entering: { width: 0, overflow: "hidden" },
      entered: { width: mobile ? "100vw" : "auto" },
      exiting: { width: 0, overflow: "hidden" },
      exited: { width: 0 },
    };

    return (
      <Transition
        in={isShown}
        timeout={{
          appear: 0,
          enter: 0,
          exit: 250,
        }}
        mountOnEnter
        unmountOnExit
      >
        {(state) => (
          <Styled.Backdrop
            onClick={clickedBackdrop}
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            <Styled.Container>{headerActionButtons}</Styled.Container>
          </Styled.Backdrop>
        )}
      </Transition>
    );
  })
);
