import styled from "styled-components";
import { themes } from "../../../config/Themes";
import { Link } from "react-router-dom";
import UserImg from "../../../assets/img/user.png";

export const BackDrop = styled.div`
  position: fixed;
  z-index: 1290;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
`;

export const Container = styled.div`
  position: fixed;
  z-index: 1300;
  height: 100vh;
  width: 280px;
  top: 0;
  left: 0;
  background: ${themes.regular.white};
  box-shadow: 4px 0 12px ${themes.regular.medium_grey};
  display: flex;
  flex-flow: column;
`;

export const User = styled.div`
  width: 100%;
  height: 56px;
  background: ${themes.regular.blue_bg};
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 20px;
`;

export const UserImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center center;
`;

export const UserName = styled.span`
  font-size: 1.2em;
  color: ${themes.regular.white};
  margin: 0;
  padding: 5px 10px;
`;

export const Menu = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  padding: 21px;
  box-sizing: border-box;
`;

export const MenuItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 0;
  color: ${themes.regular.medium_grey};
  font-size: 0.85em;

  :hover {
    color: ${themes.regular.blue_bg};
  }
`;

export const MenuLink = styled(Link)`
  color: ${themes.regular.medium_grey};
  padding-left: 16px;
  width: 100%;
  margin: 0;
`;

export const MenuWrapper = styled.div`
  display: flex;
  flex-flow: column;
  overflow: auto;
`;

export const HeaderUserImg = styled.div`
  background: url(${UserImg}) no-repeat center;
  height: 38px;
  width: 38px;
  margin-right: 10px;
`;