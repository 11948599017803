import React from "react";
import { VERSION, EXT_TITLE } from "../../../../config/consts";
import * as Styled from "./styled";
import {useDispatch} from "react-redux";
import ProfilesAction from "../../../../store/profiles/profilesAction";

export default () => {
  const dispatch = useDispatch();
  const handleLogoClick = () => {
    dispatch(ProfilesAction.clearProfilesFilter());
  }
  return (
    <Styled.HeaderLogo>
      <Styled.NavbarBrand onClick={handleLogoClick} to="/int">{EXT_TITLE} {VERSION}</Styled.NavbarBrand>
    </Styled.HeaderLogo>
  )
};
