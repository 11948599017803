import React, { useState } from "react";
import { withTranslation } from "react-i18next";
// import Auth from "../../../../configuration/API/Auth";
import AdminMenu from "../../../../shared-components/UI/AdminMenu";

import * as Styled from "./styled";
import {useSelector} from "react-redux";
import {getCurrentUser} from "../../../../store/rootScope/rootScopeSelector";

export default withTranslation()(({ t }) => {
  const [isShownUserMenu, setShownUserMenu] = useState(false);
  const {username} = useSelector((state) => getCurrentUser(state));

  const showUsermenu = () => setShownUserMenu(true);
  const hideUserMenu = () => setShownUserMenu(false);

  return (
    <Styled.HeaderUserMenu 
        onMouseEnter={showUsermenu}
        onMouseLeave={hideUserMenu}
    >
      <Styled.HeaderUserImg />
      <Styled.UserName>{username}</Styled.UserName>
      <AdminMenu isShown={isShownUserMenu} />
    </Styled.HeaderUserMenu>
  );
});
