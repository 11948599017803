import React, { memo, useCallback } from "react";
import { Transition } from "react-transition-group";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import AuthAction from "../../../store/auth/authAction";

import * as Styled from "./styled";
import LangSelector from "../LangSelector";

export default memo(
  withTranslation()(({ isShown, t, i18n }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const logout = useCallback((id) => dispatch(AuthAction.logout(id)), [dispatch]);

    const handleLogout = () => {
      logout("testID");
      history.push("/login");
    };

    return (
      <Transition in={isShown} timeout={200} mountOnEnter unmountOnExit>
        {(state) => (
          <Styled.Container
            style={{
              ...defaultMenu,
              ...transitionMenu[state],
            }}
          >
            <LangSelector />
            <Styled.UserLink to="/ext/users" name="users">
              {t("User Management")}
            </Styled.UserLink>
            <Styled.UserLink to="/ext/audit" name="audit">
              {t("Audit Trail")}
            </Styled.UserLink>
            <Styled.Logout onClick={handleLogout} name="logout">
              {t("Logout")}
            </Styled.Logout>
          </Styled.Container>
        )}
      </Transition>
    );
  })
);

const defaultMenu = {
  opacity: 0,
  transition: `all 200ms ease`,
};
const transitionMenu = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0 },
};
