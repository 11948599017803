import React from "react";

import * as Styled from "./styled";
import ExtLogo from "./ExtLogo";
import ExtMenu from "./ExtMenu";
import ExtActionButtons from "./ExtActionButtons";
import { ExtUtilityButtons } from "./ExtUtilityButtons";
import ExtNotifications from "./ExtNotifications";
import ExtBurgerMenu from "./ExtBurgerMenu";
import ExtCurrentPage from "./ExtCurrentPage";
import ExtUserMenu from "./ExtUserMenu";

export default () => (
  <Styled.Header id="topnav">
    <Styled.HeaderMenu>
      <ExtBurgerMenu />
      <ExtCurrentPage />
      <ExtLogo />
      <ExtMenu />
      <ExtUtilityButtons />
      {/*<ExtActionButtons />*/}
      {/*<ExtNotifications />*/}
      <ExtUserMenu />
    </Styled.HeaderMenu>
  </Styled.Header>
);
