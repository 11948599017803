import React, { memo, useState } from "react";
import { Transition } from "react-transition-group";
import { useWindowWidth } from "../../../../utils/useWindowWidth";

import * as Styled from "./styled";

export const ActionButton = memo(({ img, clicked, children, ...props }) => {
  const [showExportOptions, setShowExportOptions] = useState(false);
  const { exportOptions } = props;
  const mobile = useWindowWidth() < 1020;
  const handleShowOptions = (e) => {
    e.preventDefault();
    setShowExportOptions(true);
  };
  const handleHideOption = (e) => {
    e.preventDefault();
    setShowExportOptions(false);
  };

  const targetId = localStorage.getItem("webint_target_ID");

  return (
    <Styled.Button
      img={img}
      onClick={clicked}
      onMouseEnter={handleShowOptions}
      onMouseLeave={handleHideOption}
      {...props}
    >
      {mobile && children}
      {exportOptions && (
        <Transition
          in={showExportOptions}
          timeout={250}
          mountOnEnter
          unmountOnExit
        >
          {(state) => (
            <Styled.OptionsWrapper
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <Styled.ExportOptions>
                {exportOptions.map((option) => (
                  <Styled.Option
                    to={option === "PDF" ? `/target/report/${targetId}` : "#"}
                    key={option}
                  >
                    {option}
                  </Styled.Option>
                ))}
              </Styled.ExportOptions>
            </Styled.OptionsWrapper>
          )}
        </Transition>
      )}
    </Styled.Button>
  );
});

export const UploadButton = memo(({ img, changed, children }) => {
  const mobile = useWindowWidth() < 1020;
  return (
    <Styled.Label htmlFor="upload" img={img}>
      <input type="file" id="upload" onChange={changed} />
      {mobile && children}
    </Styled.Label>
  );
});

const defaultStyle = {
  transform: "scaleY(0)",
  transformOrigin: "top",
  transition: "all 250ms ease",
};
const transitionStyles = {
  entering: { transform: "scaleY(0)" },
  entered: { transform: "scaleY(1)" },
  exiting: { transform: "scaleY(1)" },
  exited: { transform: "scaleY(0)" },
};
