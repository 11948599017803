import {put, select, takeEvery} from "redux-saga/effects";
import {apiAuditService} from "../../config/api";
import RootScopeAction from "../rootScope/rootScopeAction";
import AuditAction from "./auditAction";
import {selectAuditFilter} from "./auditSelector";
import ExportFactory from "../../services/Export/ExportFactory";
import config from "../../config/config";
import {prepareAuditList} from "./auditTransformer";

function* getAuditListSaga() {
  yield put(AuditAction.setAuditLoading(true));

  try {
    const filters = yield select(selectAuditFilter);
    const data = yield apiAuditService.searchAudits(filters);
    prepareAuditList(data?.data?.content);

    yield put(AuditAction.setAuditList(data));
    yield put(AuditAction.setAuditLoading(false));
  } catch (e) {
    yield put(RootScopeAction.handleAPIError(e));
    yield put(AuditAction.setAuditLoading(false));
  }
}

function* setAuditFiltersSaga() {
  yield put(AuditAction.getAuditList());
}

function* exportAuditListSaga(action) {
  try {
    const filters = yield select(selectAuditFilter);
    yield put(AuditAction.exportAuditLoading(true));
    const {data} = yield apiAuditService.exportAudits(action.payload, {...filters, size: config.maxExportAuditLimit, page: 0});
    prepareAuditList(data?.content);

    const exportInstance = yield ExportFactory.getExportHandler(action.payload, data?.content);
    yield exportInstance.prepareAuditList();
    yield exportInstance.export();
    yield put(AuditAction.exportAuditLoading(false));
  } catch (e) {
    yield put(RootScopeAction.handleAPIError(e));
  }
}

export function* watchAudit() {
  yield takeEvery(AuditAction.GET_AUDIT_LIST, getAuditListSaga);
  yield takeEvery(AuditAction.SET_AUDIT_FILTER, setAuditFiltersSaga);
  yield takeEvery(AuditAction.CLEAR_AUDIT_FILTER, setAuditFiltersSaga);
  yield takeEvery(AuditAction.EXPORT_AUDIT_LIST, exportAuditListSaga);
}