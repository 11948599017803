import { all } from "redux-saga/effects";
import { watchAuth } from "./auth/authEffect";
import { watchRootScope } from "./rootScope/rootScopeEffect";
import { watchUsers } from "./users/usersEffect";
import { watchJobs } from "./jobs/jobsEffect";
import { watchProfiles } from "./profiles/profilesEffect";
import { watchAudit } from "./audit/auditEffect";

export function* rootSaga() {
  yield all([
    watchAuth(),
    watchRootScope(),
    watchUsers(),
    watchJobs(),
    watchProfiles(),
    watchAudit(),
  ]);
}
