import BaseReducer from "../BaseReducer";
import AuthAction from "./authAction";

export default class AuthReducer extends BaseReducer {
  initialState = {
    /*isAuth: false,
    username: null,
    roles: [],
    token: null,
    error: null,
    errorMessage: "",
    ...JSON.parse(window.sessionStorage.getItem("auth"))*/
  };

  [AuthAction.LOGIN_ERROR](state, action) {
    return {
      ...state,
      error: action.payload.error,
      errorMessage: action.payload.errorMessage,
    };
  }

  /*[AuthAction.LOGIN_SUCCESS](state, action) {
    const {username, roles = [], token} = action.payload;
    return {
      ...state,
      isAuth: true,
      username,
      roles,
      token
    };
  }*/

  [AuthAction.LOGOUT](state, action) {
    return this.initialState;
  }
}
