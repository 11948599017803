import ActionUtility from "../ActionUtility";

export default class UserSettingsAction {

  static SET_LANGUAGE = "UserSettingsAction.SET_LANGUAGE";
  static SET_ACTIVE_ROUTE = "UserSettingsAction.SET_ACTIVE_ROUTE";

  static setLanguage(language) {
    return ActionUtility.createAction(UserSettingsAction.SET_LANGUAGE, language);
  }

  static setActiveRoute(route) {
    return ActionUtility.createAction(UserSettingsAction.SET_ACTIVE_ROUTE, route)
  }
}