import React, { memo } from "react";
import SearchImg from "../../../../assets/img/search-mobile.png";

import * as Styled from "./styled";

export const FilterButton = memo(({ img, clicked, ...props }) => {
  const handleButtonClick = (e) => {
    e.preventDefault();
    clicked();
  }

  return (
    <Styled.Button
      img={SearchImg}
      onClick={handleButtonClick}
      {...props}
    />
  );
});
