import {imageByIndex} from "./imageByIndex";

const base64ByIndex = async (id) => {
  const url = imageByIndex(id);
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function() {
      const base64data = reader.result;
      const img = new Image();
      img.src = base64data;
      img.onload = function() {
        const {naturalWidth, naturalHeight} = img;
        resolve({url, base64data, id, size: {width: naturalWidth, height: naturalHeight}});
      };

    }
  });
}

export default base64ByIndex;