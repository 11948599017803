import BaseReducer from "../BaseReducer";
import UsersAction from "./usersAction";
//import { USERS as initialUsers } from "../../config/TemporaryConsts";

export default class UsersReducer extends BaseReducer {
  initialState = {
    users: [],
    pageCount: 0,
    currentPage: 0,
    sortBy: 'username',
    sorted: 'ASC',
    loading: true
  };

  [UsersAction.SET_USER_LIST](state, action) {
    return {
      ...state,
      users: action.payload.data.content,
      pageCount: action.payload.data.totalPages,
      currentPage: action.payload.data.number
    };
  }

  /*[UsersAction.CREATE_USER](state, action) {
    return {
      ...state,
      //users: state.users.concat(action.payload),
    };
  }

  [UsersAction.DELETE_USER](state, action) {
    return {
      ...state,
      //users: state.users.filter(({ id }) => id !== action.payload.id),
    };
  }*/

  /*[UsersAction.EDIT_USER](state, action) {
    const filtered = state.users.map((user) => {
      //console.log(user.username === action.payload.username);
      if (user.username === action.payload.username) {
        return { ...user, ...action.payload };
      } else {
        return user;
      }
    });
    console.log(filtered);
    return {
      ...state,
      users: filtered,
    };
  }*/

  [UsersAction.SET_USERS_LOADING](state, action) {
    return {
      ...state,
      loading: action.payload,
    };
  }
}
