import React, {memo, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { Redirect, Route } from "react-router-dom";
import {ROLES} from "../config/Routes";
import {getCurrentUser} from "../store/rootScope/rootScopeSelector";
import AuthAction from "../store/auth/authAction";

const ExtRoute = memo(
  ({ component: Component, layout: Layout, ...rest }) => {
    const dispatch = useDispatch();
    const userData = useSelector(getCurrentUser);
    const [isAuth, setAuth] = useState(null);

    useEffect(() => {
      if (userData) {
        let {roles = [], username} = userData;
        setAuth(username && roles.includes(ROLES.admin));
      }
    }, [userData]);

    useEffect(() => {
      if (isAuth === false) {
        dispatch(AuthAction.logout())
      }
    }, [isAuth]);


    return isAuth && (
      <Route
        {...rest}
        render={(props) =>
          isAuth ? (
            <Layout>
              <Component {...props} />
            </Layout>
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  }
);

export default ExtRoute;
