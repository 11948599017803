import styled from "styled-components";
import BellImg from "../../../../assets/img/bell.png";
import { themes } from "../../../../config/Themes";

export const HeaderNotifications = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 56px;
  width: 56px;
  position: relative;
  margin-left: 15px;
`;

export const Bell = styled.div`
  border: 0;
  height: 30px;
  margin: auto;
  width: 28px;
  background: url(${BellImg}) no-repeat center;

  :hover {
    opacity: .7;
  }
`;

export const Count = styled.div`
  position: absolute;
  z-index: 2;
  right: 0;
  top: 2px;
  height: 22px;
  width: 22px;
  background-color: ${themes.regular.count};
  border-radius: 100px;
  color: ${themes.regular.white};
  font-size: 0.8em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// .header__notifications .count {
//   background-color: #ef6352;
//   border-radius: 100px;
//   height: 24px;
//   position: relative;
//   right: -10px;
//   text-align: center;
//   top: -10px;
//   width: 24px;
//   z-index: 2;
// }