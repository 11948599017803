import styled, { css, keyframes } from "styled-components";
import { themes } from "../../../config/Themes";
import Refresh from "../../../assets/img/refresh.png";
import Expand from "../../../assets/img/arrow.png";
import Logout from "../../../assets/img/exit.png";
import UsersSearch from "../../../assets/img/search.png";
import UsersEdit from "../../../assets/img/users_edit.png";
import UsersDelete from "../../../assets/img/delete.png";
import Assign from "../../../assets/img/add_cases.png";
import Archived from "../../../assets/img/archived.svg";
import EditQuota from "../../../assets/img/edit_quota.png";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import DeleteCase from "../../../assets/img/delete.svg";

export const Chart = styled(EqualizerIcon)`
  color: ${themes.regular.white};
`;

const refreshing = keyframes`
  0% {transform: rotate(0deg)}
  100% {transform: rotate(360deg)}
`;
// const animation = props =>
// css`
//   ${props.isRefreshing && `${refreshing} 1s ease-in-out 0s 1`};
// `

export const Button = styled.button`
  width: 150px;
  height: 14px;
  border: none;
  outline: none;
  background-size: contain;
  // margin-left: 5px;
  transition: all 200ms linear;
  transform: rotate(180deg);
  white-space: nowrap;
  display: flex;
  align-items: center;

  :focus {
    outline: none;
  }

  ${(props) =>
    props.type === "confirm" &&
    css`
      background: ${themes.regular.blue_bg};
      min-width: 100px;
      width: min-content;
      height: 36px;
      border-radius: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1em;
      color: ${themes.regular.white};
      text-transform: uppercase;
      padding: 0 15px;
      transform: rotate(0deg);
      // margin: 0 15px;

      :hover,
      :focus {
        box-shadow: 0 0 8px ${themes.regular.light_blue_bg};
      }

      ${(props) =>
        props.disabled &&
        css`
          background: ${themes.regular.light_grey};
          :hover {
            box-shadow: 0 0 8px ${themes.regular.medium_grey};
            cursor: not-allowed;
          }
        `}
    `}

  ${(props) =>
    props.type === "cancel" &&
    css`
      background: none;
      width: auto;
      min-width: 50px;
      height: 36px;
      border-radius: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1em;
      color: ${themes.regular.blue_bg};
      text-transform: uppercase;
      padding: 0 15px;
      transform: rotate(0deg);
      margin: 0;

      :hover {
        box-shadow: 0 0 5px ${themes.regular.blue_bg};
      }

      :active {
        box-shadow: inset 0 0 4px ${themes.regular.light_blue_bg};
      }
    `}

    ${(props) =>
      props.type === "delete-quota" &&
      css`
        background: none;
        width: auto;
        min-width: 85px;
        height: 36px;
        border: 1px solid ${themes.regular.delete_quota_btn};
        border-radius: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1em;
        color: ${themes.regular.delete_quota_btn};
        text-transform: uppercase;
        padding: 0 15px;
        transform: rotate(0deg);
        margin: 0;

        :hover {
          box-shadow: 0 0 5px rgba(220, 53, 70, 0.5);
        }

        :active {
          box-shadow: inset 0 0 4px rgba(220, 53, 70, 0.5);
        }
      `}

  ${(props) =>
    props.type === "bordered" &&
    css`
      background: none;
      min-width: 100px;
      width: min-content;
      height: 36px;
      border: 2px solid ${themes.regular.blue_bg};
      border-radius: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1em;
      color: ${themes.regular.blue_bg};
      text-transform: uppercase;
      padding: 0 15px;
      transform: rotate(0deg);
      margin: 0;
      text-transform: capitalize;
      :hover,
      :focus {
        box-shadow: 0 0 8px ${themes.regular.light_blue_bg};
        opacity: 0.8;        
      }
    `}

  ${(props) =>
    props.active &&
    css`
      background: ${themes.regular.blue_bg};
      color: ${themes.regular.white};
    `}

  ${(props) =>
    props.type === "refresh" &&
    css`
      background: url(${Refresh}) no-repeat center center;
      ${props.isRefreshing &&
      css`
        animation: ${refreshing} 1s ease-in-out 0s 1;
      `}
    `}

  ${(props) =>
    props.type === "expand" &&
    css`
      background: url(${Expand}) no-repeat center center;
    `}

  ${(props) =>
    props.type === "sort" &&
    css`
      background: none;
      color: ${themes.regular.active_link};
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0;
      width: 20px;
      height: 20px;
    `}

  ${(props) =>
    props.type === "logout" &&
    css`
      background: url(${Logout}) no-repeat center center;
      transform: rotate(0deg);
    `}

  ${(props) =>
    props.type === "archived" &&
    css`
      background: url(${Archived}) no-repeat center center;
      transform: rotate(0deg);
    `}
  
    ${(props) =>
      props.type === "deleteCase" &&
      css`
        height: 14px;
        width: auto;
        background: url(${DeleteCase}) no-repeat center center;
        background-size: cover;
        transform: rotate(0deg);
      `}

  ${(props) =>
    props.isExpanded &&
    css`
      transform: rotate(0deg);
    `}

  ${(props) =>
    props.sorted &&
    css`
      transform: rotate(0deg);
    `}

  ${(props) =>
    props.type === "users-search" &&
    css`
      background: url(${UsersSearch}) no-repeat center center;
      transform: rotate(0deg);
      width: 24px;
      height: 24px;
      margin: 0;
    `}

  ${(props) =>
    props.type === "users-edit" &&
    css`
      background: url(${UsersEdit}) no-repeat center center;
      transform: rotate(0deg);
      width: 24px;
      height: 24px;
      margin: 0;
    `}

  ${(props) =>
    props.type === "users-delete" &&
    css`
      background: url(${UsersDelete}) no-repeat center center;
      transform: rotate(0deg);
      width: 24px;
      height: 24px;
      margin: 0;
    `}

  ${(props) =>
    props.type === "edit-quota" &&
    css`
      background: url(${EditQuota}) no-repeat center center;
      transform: rotate(0deg);
      width: 24px;
      height: 24px;
      margin: 0;
    `}

  ${(props) =>
    props.type === "assign" &&
    css`
      background: url(${Assign}) no-repeat center center;
      background-color: ${themes.regular.white};
      border-radius: 50%;
      transform: rotate(0deg);
      width: 20px;
      height: 20px;
      // margin: 0 5px 0 5px;
      padding: 0;

      :hover {
        opacity: .7;
      }
    `}

  ${(props) =>
    props.type === "chart" &&
    css`
      background: ${themes.regular.status_green};
      transform: rotate(0deg);
      min-width: 36px;
      min-height: 36px;
      margin: 5px 0;
      border-radius: 18px;
      display: flex;
      align-items: center;
      color: ${themes.regular.white};
      width: fit-content;

      :hover {
        box-shadow: 0 0 6px ${themes.regular.medium_grey};
      }
    `}

  ${(props) =>
    props.type === "export" &&
    css`
      background: ${themes.regular.green};
      width: auto;
      height: 36px;
      border-radius: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1em;
      color: ${themes.regular.white};
      text-transform: uppercase;
      padding: 0 15px;
      transform: rotate(0deg);
      margin: 0 15px;

      :hover {
        box-shadow: 0 0 6px ${themes.regular.medium_grey};
      }
    `}
`;
