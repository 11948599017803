import i18n from "i18next";
import moment from "moment";
import {AuditTypes} from "../../../pages/Ext/Audit/const";
import * as FileSaver from "file-saver"

class ExportCsv {
  constructor(data) {
    this.data = [];
    if (Array.isArray(data)) {
      this.rawData = [...data];
    } else {
      this.rawData = {...data};
    }
    this.fileName = "PROFILES_" + moment().format("YYYY_MM_DD_HH_mm_ss");
  }
  prepareAuditList() {
    //helpers
    const getAuditLabel = type => {
      const foundType = AuditTypes.find(t => t.value === type);
      return foundType ? foundType.label : type;
    }

    this.data.push([
      i18n.t('Date'),
      i18n.t('Time'),
      i18n.t('Action Type'),
      i18n.t('Username'),
      i18n.t('Details'),
      //i18n.t('IP'),
      i18n.t('Args'),
    ])
    this.rawData.map(row => {
      let { actionType, createdTimestamp, details, username, args } = row;
      //args = ["IMPORT##",null,null,null];
      this.data.push([
        moment(createdTimestamp).format("YYYY/MM/DD"),
        moment(createdTimestamp).format("HH:mm:ss"),
        getAuditLabel(actionType),
        username,
        details,
        //ipAddress,
        JSON.stringify(args),
      ])
    })
    //console.log('this.data', this.data);
    this.fileName = "AUDIT_" + moment().format("YYYY_MM_DD_HH_mm_ss");
    return this;
  }
  export() {
    const universalBOM = "\uFEFF";

    const csvData = universalBOM + this.data.map(e => e.join(";")).join("\n");
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    FileSaver.saveAs(blob, `${this.fileName}.csv`);

    /*let csvContent = "data:text/csv;charset=utf-8," + universalBOM + this.data.map(e => e.join(";")).join("\n");
    const encodedUri = encodeURI(csvContent);

    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${this.fileName}.csv`);
    document.body.appendChild(link);
    link.click();*/

    return this;
  }
}

export default ExportCsv