import React from "react";
import { withRouter } from "react-router-dom";
import * as Styled from "./styled";
import { withTranslation } from "react-i18next";

const MainMenu = withTranslation()(({ t, match }) => {
  return (
    <Styled.HeaderNavMenu></Styled.HeaderNavMenu>
  );
});

export default withRouter(MainMenu);
