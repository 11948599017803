import * as XLSX from "xlsx"
import * as FileSaver from "file-saver"
import i18n from "i18next";
import {SupportService} from "../../support";
import moment from "moment";
import {AuditTypes} from "../../../pages/Ext/Audit/const";

class ExportXls {
  constructor(data) {
    this.data = [];
    if (Array.isArray(data)) {
      this.rawData = [...data];
    } else {
      this.rawData = {...data};
    }
    this.fileName = "PROFILES_" + moment().format("YYYY_MM_DD_HH_mm_ss");
  }
  prepareProfileList() {
    this.data.push([
      i18n.t('Phone'),
      i18n.t('Name'),
      i18n.t('Online'),
      i18n.t('Networks'),
      i18n.t('Sources')
    ])
    this.rawData.map(row => {
      this.data.push([
        row.phone,
        row.names?.[0],
        row.sources.length ? "+" : "-",
        row.socialNetworks.map(s => SupportService.getSocialUrl(s.socialNetwork, s.id)).join(", "),
        row.sources.join(", ")
      ])
    })
    return this;
  }
  prepareProfile() {
    const {target, sources} = this.rawData;

    this.fileName = "PROFILE_" + target?.phone + '_' + moment().format("YYYY_MM_DD_HH_mm_ss");
    this.data.push([
      i18n.t('Source'),
      i18n.t('Phone'),
      i18n.t('Created'),
      i18n.t('Names'),
      i18n.t('Profiles'),
      i18n.t('Emails'),
      i18n.t('Country'),
      i18n.t('City'),
      //i18n.t('Id'),
      i18n.t('Last Online'),
    ]);
    sources.map(row => {
      this.data.push([
        row.lmSource,
        row.profile?.phone,
        moment(row.profile?.createdTimestamp).format("YYYY/MM/DD HH:mm:ss"),
        row.profile?.names?.join(', '),
        row.profile?.socialNetworks?.map(s => SupportService.getSocialUrl(s.socialNetwork, s.id))?.join(", "),
        row.profile?.emails?.join(", "),
        row.profile?.country,
        row.profile?.city,
        //row.profile?.id,
        moment(row.profile?.lastOnline, 'x', true).isValid()
          ? moment(row.profile?.lastOnline, 'x').format("Y.MM.DD HH:mm:ss")
          : row.profile?.lastOnline,
      ])
    });
    console.log('ExportXls', this.rawData);
    return this.data;
  }
  prepareAuditList() {
    //helpers
    const getAuditLabel = type => {
      const foundType = AuditTypes.find(t => t.value === type);
      return foundType ? foundType.label : type;
    }

    this.data.push([
      i18n.t('Date'),
      i18n.t('Time'),
      i18n.t('Action Type'),
      i18n.t('Username'),
      i18n.t('Details'),
      //i18n.t('IP'),
      i18n.t('Args'),
    ])
    this.rawData.map(row => {
      const { actionType, createdTimestamp, details, username, args } = row;
      this.data.push([
        moment(createdTimestamp).format("YYYY/MM/DD"),
        moment(createdTimestamp).format("HH:mm:ss"),
        getAuditLabel(actionType),
        username,
        details,
        //ipAddress,
        JSON.stringify(args),
      ])
    })
    this.fileName = "AUDIT_" + moment().format("YYYY_MM_DD_HH_mm_ss");
    return this;
  }
  export() {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.aoa_to_sheet(this.data);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, this.fileName + fileExtension);

    return this;
  }
}

export default ExportXls