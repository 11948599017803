import styled from "styled-components";
import { themes } from "../../config/Themes";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  position: relative;
`;

export const Content = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  background: ${themes.regular.main_bg};
`;

export const Wrapper = styled.div`
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 1600px) {
    max-width: 80%;
  }

  @media (max-width: 1600px) {
    max-width: 95%;
  }

  @media (max-width: 1280px) {
    max-width: 100%;
  }

  @media (max-width: 1200px) {
    max-width: 1140px;
  }

  @media (max-width: 992px) {
    max-width: 960px;
  }

  @media (max-width: 768px) {
    max-width: 720px;
  }

  @media (max-width: 576px) {
    max-width: 540px;
  }
`;