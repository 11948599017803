export function prepareAuditList(list = []){
  list.map(audit => {
    if (audit?.args && audit.args?.length) {
      audit.args.map(arg => {
        if (arg?.password) {
          arg.password = '***';
        }
      })
    }
  })
  return list;
}