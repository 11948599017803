import React from "react";
import moment from "moment";

import * as fs from "file-saver";
import { Packer } from "docx";
import ExportDocxProfiles from "./ExportDocxProfiles";
import ExportDocxProfile from "./ExportDocxProfile";


class ExportDocx {
  constructor(data) {
    this.doc = null;
    this.rawData = Array.isArray(data) ? [...data] : {...data};
    this.fileName = "PROFILES_" + moment().format("YYYY_MM_DD_HH_mm_ss");
  }
  prepareProfileList() {
    this.doc = ExportDocxProfiles.renderDoc(this.rawData);
    return this;
  }
  prepareProfile() {
    const {target} = this.rawData;
    this.fileName = "PROFILE_" + target?.phone + '_' + moment().format("YYYY_MM_DD_HH_mm_ss");
    this.doc = ExportDocxProfile.renderDoc(this.rawData);
    return this;
  }
  export() {
    Packer.toBlob(this.doc).then((blob) => {
      fs.saveAs(blob, this.fileName);
    });
    return this;
  }

  //other methods
}

export default ExportDocx