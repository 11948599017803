import React, { memo } from "react";
import IntHeader from "./IntHeader";
import IntFooter from "./IntFooter";

import * as Styled from "./styled";


const IntLayout = memo(({ children }) => {
    return (
        <>
            {/*<div>Ext Layout. Admin: {userData.username}</div>*/}
            <Styled.Container>
                <IntHeader />
                <Styled.Content>
                    <Styled.Wrapper>{children}</Styled.Wrapper>
                </Styled.Content>
                <IntFooter />
            </Styled.Container>
        </>
    );
});

export default IntLayout;
