import styled from "styled-components";
import { Link } from "react-router-dom";
import { themes } from "../../../../config/Themes";

export const HeaderLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 8px;

  @media (max-width: ${themes.regular.wide_screen}) {
    display: none;
  }  
`;

// .header__logo .logo {
//   background-color: #75b4e6;
//   font-family: 'Open Sans', sans-serif;
//   font-size: 1.125em;
//   font-weight: 400;
//   padding: .5em;
//   position: relative;
//   text-transform: uppercase;
//   top: 15%;
//   width: 100%;
// }

export const NavbarBrand = styled(Link)`
  color: ${themes.regular.white};
  display: inline-block;
  padding-top: .3125em;
  padding-bottom: .3125em;
  margin-right: 1em;
  font-size: 1.25em;
  line-height: inherit;
  white-space: nowrap; 

  img {
    max-width: 170px;
    height: 50px;
  }
  &:hover {
    color: ${themes.regular.light_grey};
  }  
`;
// .navbar-brand:hover{
//   color:#cbcbcb;
// }

