import * as Styled from "./styled";

import React, {useCallback} from "react";
import {withTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import UserSettingsAction from "../../../store/userSettings/userSettingsAction";

import EN from "../../../assets/img/flags/gb.svg";
import UZ from "../../../assets/img/flags/uz.svg";
import RU from "../../../assets/img/flags/ru.svg";

const LangSelector = ({t, i18n}) => {
  const chosenLang = useSelector((state) => state.settings.chosenLang);
  const dispatch = useDispatch();
  const setLanguage = useCallback(
    (language) => dispatch(UserSettingsAction.setLanguage(language)),
    [dispatch]
  );

  const handleClick = (e) => {
    console.log(e.target.name);
    localStorage.setItem("webint_lang", e.target.name);
    setLanguage(e.target.name);
    i18n.changeLanguage(e.target.value, () => {
      console.log("lang is changed")
      window.location.reload();
    });
  };

  return (
    <Styled.SelectLanguage>
      <span>{t("Language")}:</span>
      <Styled.LangOptions>
        <Styled.Option
          alt={t("English")}
          title={t("English")}
          src={EN}
          name="en"
          onClick={handleClick}
          chosen={chosenLang === "en"}
        />
        <Styled.Option
          alt={t("Uzbek")}
          title={t("Uzbek")}
          src={UZ}
          name="uz"
          onClick={handleClick}
          chosen={chosenLang === "uz"}
        />
        <Styled.Option
          alt={t("Uzbek Cyrillic")}
          title={t("Uzbek Cyrillic")}
          src={UZ}
          name="uz_cyrl"
          onClick={handleClick}
          chosen={chosenLang === "uz_cyrl"}
        />
        <Styled.Option
          alt={t("Russian")}
          title={t("Russian")}
          src={RU}
          name="ru"
          onClick={handleClick}
          chosen={chosenLang === "ru"}
        />
      </Styled.LangOptions>
    </Styled.SelectLanguage>
  )
}

export default withTranslation()(LangSelector);