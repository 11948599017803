import BaseReducer from "../BaseReducer";
import AuditAction from "./auditAction";
import config from "../../config/config";

export default class AuditReducer extends BaseReducer {
  initialState = {
    list: [],
    filters: {
      page: 0,
      size: config.profilesPageSize,
    },
    loading: true,
    exportLoading: false,
  };

  [AuditAction.SET_AUDIT_LIST](state, action) {
    return {
      ...state,
      list: action.payload.data
    };
  }

  [AuditAction.SET_AUDIT_LOADING](state, action) {
    return {
      ...state,
      loading: action.payload,
    };
  }

  [AuditAction.SET_AUDIT_FILTER](state, action) {
    return {
      ...state,
      filters: {
        ...state.filters,
        ...action.payload
      },
    };
  }

  [AuditAction.CLEAR_AUDIT_FILTER](state) {
    return {
      ...state,
      filters: this.initialState.filters,
    };
  }

  [AuditAction.UPDATE_AUDIT_STATE](state, action) {
    return {
      ...state,
      ...action.payload,
    }
  }

  [AuditAction.EXPORT_AUDIT_LOADING](state, action) {
    return {
      ...state,
      exportLoading: action.payload,
    };
  }
}
