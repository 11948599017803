import {
  Document,
  Paragraph,
  TextRun,
  Table,
  TableCell,
  TableRow,
  VerticalAlign,
  TextDirection,
  HeadingLevel,
  Footer,
  AlignmentType,
  PageNumber,
} from "docx";
import i18n from "i18next";
import {SupportService} from "../../../support";

class ExportDocxProfiles {
  static createFooter = () => {
    return {
      footers: {
        default: new Footer({
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun(i18n.t('Powered By') + ': ESPY.'),
                new TextRun({
                  children: [i18n.t("Page") + ": ", PageNumber.CURRENT],
                }),
                new TextRun({
                  children: [" " + i18n.t("of") + " ", PageNumber.TOTAL_PAGES],
                }),
              ],
            }),
          ],
        }),
      },
    }
  }
  static createTable = (doc, columns, data) => {
    const rows = [];

    //header
    const headers = [];
    columns.map(column => {
      headers.push(new TableCell({
        children: [new Paragraph({ text: column })],
      }))
    });
    rows.push(new TableRow({
      children: headers
    }));

    //main data
    data.map(row => {
      rows.push(new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: row.phone,
              }),
            ],
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: row.names?.[0],
              }),
            ]
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: row.sources.length ? "+" : "-",
              }),
            ]
          }),
          new TableCell({
            children: row.socialNetworks.map(s =>
              new Paragraph({
                children: [doc.createHyperlink(SupportService.getSocialUrl(s.socialNetwork, s.id), s.socialNetwork)],
              })
            ),
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: row.sources.join(", ")
              }),
            ]
          })
        ]
      }))
    });

    return  new Table({
      rows: [
        ...rows,
      ],
    });
  }

  static renderDoc(data) {
    const doc = new Document();
    const table = this.createTable(doc, [
      i18n.t('Phone'),
      i18n.t('Name'),
      i18n.t('Online'),
      i18n.t('Networks'),
      i18n.t('Sources')
    ], data);

    doc.addSection({
      children: [table],
      ...this.createFooter()
    });


    return doc;
  }
}

export default ExportDocxProfiles;