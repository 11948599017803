import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Buttons from "../../../../shared-components/Buttons/HeaderActionButtons";
import { useWindowWidth } from "../../../../utils/useWindowWidth";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Container, More } from "./styled";

const MainActionButtons = () => {
  const width = useWindowWidth();
  const [shownButtons, setShownButtons] = useState(false);
  const toggleShowButtons = (e) => {
    e.preventDefault();
    setShownButtons(!shownButtons);
  };
  const hideButtons = () => setShownButtons(false);
  const path = useLocation().pathname;

  return (
    <Container>
      {width < 1020 && path !== "/users/audit" && (
        <More onClick={toggleShowButtons}>
          <MoreVertIcon />
        </More>
      )}
      <Buttons
        isShown={shownButtons || width > 1020}
        clickedBackdrop={hideButtons}
      />
    </Container>
  );
};

export default MainActionButtons;
