import config from '../config/config'
import moment from "moment";
import md5 from "md5"

export class SupportService {
  static getBase64Image(img) {
    console.log(img);
    // img.crossOrigin = "Anonymous";
    var canvas = document.createElement("canvas"),
      dataURL;
    canvas.width = img.width;
    canvas.height = img.height;

    // Copy the image contents to the canvas
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    // Get the data-URL formatted image
    // Firefox supports PNG and JPEG. You could check img.src to
    // guess the original format, but be aware the using "image/jpg"
    // will re-encode the image.
    dataURL = canvas.toDataURL("image/png");

    return dataURL;
    //return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  static isWrongPath(str) {
    if (str.indexOf("/") === 0 && str.substr(str.length - 3, 1) === ".") {
      return true;
    }
    if (str.indexOf("/") === 0 && str.substr(str.length - 4, 1) === ".") {
      return true;
    }

    return false;
  }

  static ifNull(obj, key, defVal) {
    var keys = key.split("."),
      value;
    for (var i = 0; i < keys.length; i++) {
      if (obj && typeof obj[keys[i]] !== "undefined") {
        value = obj = obj[keys[i]];
      } else {
        return defVal;
      }
    }
    return value;
  }

  static isJSON(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  static isBase64(url) {
    if (
      url.indexOf("http:") === -1 &&
      url.indexOf("https:") === -1 &&
      url.indexOf("/app/") !== 0 &&
      url.indexOf("img") !== 0
    ) {
      return true;
    }
    return false;
  }

  static objectSize(obj) {
    const size = Object.keys(obj).length;
    return size;
  }

  static getMd5Color = function(text) {
    if (!text) return false
    const ColorLuminance = function(hex, lum) {

      // validate hex string
      hex = String(hex).replace(/[^0-9a-f]/gi, '');
      if (hex.length < 6) {
        hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
      }
      lum = lum || 0;

      // convert to decimal and change luminosity
      let rgb = "", c, i;
      for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i*2,2), 16);
        c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
        rgb += ("00"+c).substr(c.length);
      }

      return rgb;
    };
    let color = md5(text).substring(0, 6);
    color = ColorLuminance(color, -0.2);

    return color;
  };

  static getUrl(url) {
    if (url) {
      if (url.indexOf("http") === 0) {
        return url;
      }
      if (url.indexOf("//") === 0) {
        return url;
      }
      return "https://" + url;
    }
  }

  static validateRoleAdmin({roles = []}) {
    let isAdmin = false;
    const adminRoles = config.roles.admin_roles;
    if (!Array.isArray(roles)) {
      roles = roles.split(',');
    }
    roles.forEach(role => {
      if (adminRoles.includes(role)) return (isAdmin = true);
    });

    return isAdmin;
  }
  static validateRoleUser({roles = []}) {
    let isUser = false;
    const userRoles = config.roles.user_roles;
    if (!Array.isArray(roles)) {
      roles = roles.split(',');
    }
    roles.forEach(role => {
      if (userRoles.includes(role)) return (isUser = true);
    });
    return isUser;
  }

  static isFiltered(filters = {}) {
    let is = false;
    Object.keys(filters).filter(key => key !== 'page' && key !== 'size').forEach(filterKey => {
      if (filters[filterKey]) {
        is = true;
      }
    })
    return is;
  }
  static momentToDate(str) {
    return moment.unix(str / 1000).toDate();
  }
  static prepareFilters(filters) {
    const preparedFilters = {};
    Object
      .keys(filters)
      .filter(key => key !== 'page')
      .map(key => {
        if (filters[key] !== "" && filters[key] !== null) {
          preparedFilters[key] = filters[key];
        }
      })
    return preparedFilters;
  }
  static getSocialUrl = function(network, id) {
    if (network === 'FACEBOOK') return 'https://facebook.com/' + id;
    if (network === 'FB') return 'https://facebook.com/' + id;
    if (network === 'TWITTER') return 'https://twitter.com/' + id;
    if (network === 'LINKEDIN') return 'https://www.linkedin.com/in/' + id;
    if (network === 'LI') return 'https://www.linkedin.com/in/' + id;
    if (network === 'GOOGLEPLUS' || network === 'GOOGLE_PLUS') return 'https://plus.google.com/' + id;
    if (network === 'GPLUS') return 'https://plus.google.com/' + id;
    if (network === 'ANGELLIST') return 'https://angel.co/' + id;
    if (network === 'FOURSQUARE') return 'https://foursquare.com/user/' + id;
    if (network === 'OK') {
      let okLink = 'https://ok.ru/';
      if(isNaN(id)) {
        return okLink + id;
      } else {
        return okLink + 'profile/' + id;
      }
    }
    if (network === 'VK') {
      if (/^\d+$/.test(id)) {
        return 'https://vk.com/id' + id;
      } else {
        return 'https://vk.com/' + id;
      }
    }
    if (network === 'INSTAGRAM') return 'https://www.instagram.com/' + id;
    if (network === 'TELEGRAM') return 'https://t.me/' + id;
    if (network === 'MY_MAIL_RU') return 'https://my.mail.ru/' + id;


    return false;
  };
  static round = value => {
    return Math.round((value + Number.EPSILON) * 100) / 100;
  }

  static getTitle = (text) => {
    let finalResult = text;
    if (text) {
      if (text.indexOf("_") > 0) {
        finalResult = text
          .split("_")
          .map(
            (name) => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
          )
          .join(" ");
      } else {
        const result = text.replace(/([A-Z])/g, " $1");
        finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      }
    }

    return finalResult;
  };

  static isStringsArray = arr => arr.every(i => typeof i === "string")
  static isObjectsArray = arr => arr.every(i => i && typeof i === "object")

  static isHttpUrl(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  static isBase64Url(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === 'data:';
  }

  static getImageSrc(url) {
    if (url) {
      if (url.indexOf("http") === 0) {
        return url;
      }
      if (url.indexOf("/app/img/") === 0) {
        return url;
      }
      if (url.indexOf("data:image") === 0) {
        return url;
      }
      if (url.indexOf("i.mycdn") === 0) {
        return `//${url}`;
      }
      return "data:image/png;base64," + url;
    }
  }
}
