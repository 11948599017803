import styled from "styled-components";

export const Button = styled.div`
  width: 26px;
  height: 26px;
  margin: 1px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${(props) => props.img});
  transition: all 250ms ease;
  position: relative;

  :hover {
    cursor: pointer;
    opacity: 0.7;
  }

  @media (min-width: 1280px) {
    display: none;
  }
`;
