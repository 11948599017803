export const themes = {
  regular: {
    main_bg: "#FBFBFB",
    white: "#FFFFFF",
    footer_copyright: "#B1B1B1",
    blue_bg: "#3994DB",
    dark_blue: "#1A7AC5",
    light_blue_bg: "#75B4E6",
    light_grey: "#DDDDDD",
    medium_grey: "#777777",
    widget_border: "#F3F3F3",
    yellow: "rgb(249,231,132)",
    green: "rgb(171,225,136)",
    dark_green: "#078C3C",
    red: "rgb(238,99,82)",
    darkweb: "#303030",
    form_bg: "#F8F9FA",
    count: "#EF6352",
    badge_warning_bg: "#FFC107",
    badge_warning_text: "#111111",
    targets_text: "#919191",
    create_case_text: "#A7B4C7",
    active_link: "#007BFF",
    status_red: "#FF5C45",
    status_yellow: "#FFA500",
    status_green: "#8BC34A",
    report_text: "#425773",
    alert_text: "#A5A5A5",
    text_input_placeholder: "#CCCCCC",
    black_label: '#403838',
    toasts: {
      error: "#D00408",
      success: "#078C3C",
      warning: "#FFA500",
      general: "#3994DB",
    },
    delete_quota_btn: "#DC3545",
    wide_screen: "1024px",
    popup: {
        xs: '350px',
        sm: '500px',
        lg: '800px',
        xl: '990px',
    },
    scan_wrapper: {width: 640, height: 480}
  }
}