import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { VERSION } from "../../../config/consts";
import { themes } from "../../../config/Themes";
import styled from "styled-components";

import LoadingAnimation from "react-fullscreen-loading";

const Container = styled.div`
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6em;
  color: ${themes.regular.blue_bg};
`;

const useStyles = makeStyles(() => ({
  root: {
    height: 18,
    margin: "0 0 12px",
    borderRadius: 12,
    color: `${themes.regular.blue_bg}`,
    "& > MuiLinearProgress-barColorPrimary": {
      color: `${themes.regular.blue_bg}`,
    },
  },
  color: {
    backgroundColor: `${themes.regular.light_blue_bg}`,
    color: "salmon",
    "& > MuiLinearProgress-barColorPrimary": {
      color: `${themes.regular.blue_bg}`,
    },
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <>
        <LoadingAnimation loading={true} background="#fff" loaderColor="#3498db" />
      {/*<Container>Mas Mapper UI {VERSION}</Container>
      <LinearProgress
        color="primary"
        classes={{
          root: classes.root,
          colorPrimary: classes.color,
        }}
      />*/}
    </>
  );
};
