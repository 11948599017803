import styled from "styled-components";
import { themes } from "../../../../config/Themes";
import MenuImg from "../../../../assets/img/menu.png";

export const BurgerButton = styled.button`
  padding: 0;
  margin: 0 15px;
  border: none;
  background: url(${MenuImg}) no-repeat center center;
  background-size: cover;
  box-sizing: border-box;
  height: 24px;
  width: 24px;
  align-items: center;
  justify-content: center;
  display: none;
  color: ${themes.regular.white};

  @media (max-width: ${themes.regular.wide_screen}) {
    display: flex;
    flex-grow: 0;
  }

  :hover {
    cursor: pointer;
  }
  :focus {
    outline: none;    
  }
`;
