import {
  Document,
  Paragraph,
  TextRun,
  Table,
  TableCell,
  TableRow,
  VerticalAlign,
  TextDirection,
  HeadingLevel,
  Footer,
  AlignmentType,
  PageNumber, TabStopType, TabStopPosition, Media, WidthType,BorderStyle
} from "docx";
import i18n from "i18next";
import {imageByIndex} from "../../../../helpers/imageByIndex";
import moment from "moment";
import {SupportService} from "../../../support";
import {Link} from "@react-pdf/renderer";
import React from "react";

class ExportDocxProfile {
  static dimensions = {};
  static createFooter = () => {
    return {
      footers: {
        default: new Footer({
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun(i18n.t('Powered By') + ':  ESPY.   '),
                new TextRun({
                  children: [i18n.t("Page") + ": ", PageNumber.CURRENT],
                }),
                new TextRun({
                  children: [" " + i18n.t("of") + " ", PageNumber.TOTAL_PAGES],
                }),
              ],
            }),
          ],
        }),
      },
    }
  }
  static createColumns = (textColumns, position = 1000) =>  {
    const columns = [];
    textColumns.map((col, key) => {
      if (col.text && key > 0) {
        col.text = `\t${col.text}`;
      }
      columns.push(new TextRun(col));
    });

    return new Paragraph({
      tabStops: [
        {
          type: TabStopType.LEFT,
          //position: docx.TabStopPosition.MAX,
          position,
        },
      ],
      children: columns,
    })
  }
  static createAlignedColumns = (leftText, rightText) => {
    return new Paragraph({
      tabStops: [
        {
          type: TabStopType.RIGHT,
          position: TabStopPosition.MAX,
        },
      ],
      children: [
        new TextRun({
          text: leftText,
          bold: true,
        }),
        new TextRun({
          text: `\t${rightText}`,
          bold: true,
        }),
      ],
    });
  }
  static createHeading = text => {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_1,
      thematicBreak: true,
    });
  }
  static createSubHeading = text => {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_4,
      thematicBreak: true,
    });
  }
  static createEmptySpace = () => {
    return new Paragraph({
      text: ' ',
      heading: HeadingLevel.HEADING_1,
    });
  }
  static createTable = (rows = [], options = {}) => {
    const sourceRows = [];
    const margins = {
      top: 60,
      bottom: 0,
      left: 0,
      right: 0,
    };
    const borders = {
      top: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
      bottom: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
      left: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
      right: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
    }
    rows.map(({header, value}) => {
      sourceRows.push(new TableRow({
        children: [
          new TableCell({
            children: [new Paragraph({children: [ new TextRun({text: header || '', bold: true})]})],
            width: {
              size: 30,
              type: WidthType.PERCENTAGE,
            },
            borders,
            margins,
          }),
          new TableCell({
            children: [new Paragraph(value || '')],
            width: {
              size: 70,
              type: WidthType.PERCENTAGE,
            },
            borders,
            margins,
          })
        ],
      }))
    });
    return new Table({
      rows: sourceRows,
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      borders: {
        top: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
        bottom: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
        left: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
        right: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
      },
    });
  }
  static createImage = (doc, src, options) => {
    try {
      const blob = fetch(imageByIndex(src)).then(r => r.blob());
      return Media.addImage(doc, blob, options?.width || 370, options?.height || 370, {});
    } catch (e) {

    }
  }
  static fitImage = (doc, src, base64images, targetWidth, targetHeight) => {
    //console.log('base64images', base64images);
    const base64image = id => {
      return base64images.find(b => b.id === id);
    }
    //const image = fetch(imageByIndex(src)).then(r => r.blob());
    //console.log('sizeOf', imageSize(imageByIndex(src)));
    //return false;
    //const originalSize = imageSize(image);

    try{
      const {base64data: image, size: originalSize} = base64image(src);
      const originalAspectRatio = originalSize.width / originalSize.height;

      let width;
      let height;

      if (!targetWidth) {
        // fixed height, calc width
        height = targetHeight;
        width = height * originalAspectRatio;
      } else if (!targetHeight) {
        // fixed width, calc height
        width = targetWidth;
        height = width / originalAspectRatio;
      } else {
        const targetRatio = targetWidth / targetHeight;
        if (targetRatio > originalAspectRatio) {
          // fill height, calc width
          height = targetHeight;
          width = height * originalAspectRatio;
        } else {
          // fill width, calc height
          width = targetWidth;
          height = width / originalAspectRatio;
        }
      }

      try {
        return Media.addImage(doc, image, width, height);
      } catch (e) {}

    } catch (e) {}
    //console.log(originalSize, originalAspectRatio, width, height);
  }
  /*static determineImages = ({sources}, callback) => {
    sources.map(({profile}) => {
      profile.photos.map(image => {
        this.dimensions[image] = imageByIndex(image);
      });
    });
  }*/



  static renderTitlePage = (doc, {target, base64images = []}) => {
    let mainImage = new Paragraph({
      text: "\n\n\n\n\n\n\n\n",
      heading: HeadingLevel.HEADING_1,
      alignment: AlignmentType.CENTER,
    });

    /*try {
      const blob = fetch(imageByIndex(target.images[0])).then(r => r.blob());
      mainImage = Media.addImage(doc, blob, 370, 370, {});
    } catch (e) {}*/
    if (target.images.length) {
      mainImage = new Paragraph({
        children: [
          //this.createImage(doc, target.images[0])
          this.fitImage(doc, target.images[0], base64images, 370, 370)
        ],
        alignment: AlignmentType.CENTER,
      });
    }

    doc.addSection({
      ...this.createFooter(),
      children: [
        mainImage,
        new Paragraph({
          text: (target?.names?.[0] || '') + "\n\n\n\n\n\n\n\n",
          heading: HeadingLevel.HEADING_1,
          alignment: AlignmentType.CENTER,
        }),
        new Paragraph({
          text: target?.phone + "\n\n\n\n\n\n\n\n",
          heading: HeadingLevel.HEADING_3,
          alignment: AlignmentType.CENTER,
        }),
      ],
    });
  }
  static renderSources = (doc, {target, sources, base64images = []}) => {
    const items = [];
    sources.map(source => {
      const sourceRows = [];

      items.push(this.createHeading(source.lmSource));
      [
        {header: i18n.t("Country"), value: source?.profile?.country},
        {header: i18n.t("City"), value: source?.profile?.city},
        //{header: i18n.t("ID"), value: source?.profile?.id},
        {header: i18n.t("Created"), value: moment(source?.profile?.createdTimestamp)?.format("Y.MM.DD HH:mm:ss")},
        {header: i18n.t("Names"), value: source?.profile?.names?.join(', ')},
        {header: i18n.t("Emails"), value: source?.profile?.emails?.join(', ')},
        {header: i18n.t("Phone"), value: source?.profile?.phone},
        {header: i18n.t("Last Online"), value:
            moment(source?.profile?.lastOnline, 'x', true).isValid()
              ? moment(source?.profile?.lastOnline, 'x').format("Y.MM.DD HH:mm:ss")
              : source?.profile?.lastOnline
        },
      ].map(({header, value}) => {
        /*items.push(this.createColumns(
          [
            {text: header, bold: true},
            {text: value},
          ], 3000
        ));*/
        sourceRows.push({header, value});
      });
      items.push(this.createTable(sourceRows));

      if (source.profile?.socialNetworks?.length) {
        new Paragraph({
          text: " ",
          heading: HeadingLevel.HEADING_3,
        });
        source.profile.socialNetworks.map(({id, socialNetwork}) => {
          items.push(this.createEmptySpace());
          items.push(new Paragraph({
            text: socialNetwork,
            heading: HeadingLevel.HEADING_3,
          }));
          items.push(
            new Paragraph({
              children: [
                socialNetwork,
                doc.createHyperlink(SupportService.getSocialUrl(socialNetwork, id), id),
              ]
            })
          )
        })
      }
      items.push(...this.renderDeepWebSources(source))
      items.push(this.createEmptySpace());
      const images = [];
      source.profile.photos.map(image => {
        images.push(
          //this.createImage(doc, image, {width: 200, height: 200})
          this.fitImage(doc, image, base64images, 200, 200)
        );
      });
      if (images.length) {
        items.push(new Paragraph({
          children: images
        }))
        items.push(this.createEmptySpace());
      }

    })
    //console.log('items', items);

    doc.addSection({
      ...this.createFooter(),
      children: items,
    });
  }

  static renderDeepWebSources = (source) => {
    const items = [];
    const {lmSource, profile} = source;
    profile.socialMap = [];
    if (lmSource === 'DEEPWEB' && profile.other) {
      for (const [socialNetwork, entries = [] ] of Object.entries(profile.other)) {
        const socialMap = [];
        entries.map(entry => {
          socialMap.push({...entry, socialNetwork, phone: profile.phone})
        })
        profile.socialMap = socialMap;
      }
    }

    profile.socialMap.map(searchRes => {
      const sourceRows = [];
      items.push(this.createEmptySpace());
      items.push(this.createSubHeading(searchRes.socialNetwork + ' : ' + searchRes.socialNetworkId));
      [
        {header: i18n.t('First Name'), value: searchRes?.firstName},
        {header: i18n.t('Last Name'), value: searchRes?.lastNames?.join(', ')},
        {header: i18n.t('Maiden Name'), value: searchRes?.maidenName},
        {header: i18n.t('Emails'), value: searchRes?.emails?.join(', ')},
        {header: i18n.t('Phones'), value: searchRes?.phones?.join(', ')},
        {header: i18n.t('Addresses'), value: searchRes?.addresses?.join(', ')},
        {header: i18n.t('Additional Contact'), value: searchRes?.additionalContact},
        {header: i18n.t('Site'), value: searchRes?.site},
        {header: i18n.t('Birth Date'), value: searchRes?.birthday},
        {header: i18n.t('Gender'), value: searchRes?.gender},
        {header: i18n.t('Married'), value: searchRes?.married},
        {header: i18n.t('Religion'), value: searchRes?.religion},
        {header: i18n.t('Political'), value: searchRes?.political},
        {header: i18n.t('Friends'), value: searchRes?.friends},
        {header: i18n.t('About'), value: searchRes?.about},
        {header: i18n.t('University'), value: searchRes?.university},
        {header: i18n.t('Faculty'), value: searchRes?.faculty},
        {header: i18n.t('Chair'), value: searchRes?.chair},
        {header: i18n.t('University Year End'), value: searchRes?.universityYearEnd},
        {header: i18n.t('Work'), value: searchRes?.work},
        {header: i18n.t('Schools'), value: searchRes?.schools},
      ].map(({header, value}) => {
        sourceRows.push({header, value});
      });
      items.push(this.createTable(sourceRows));
    })
    items.push(this.createEmptySpace());
    return items;
  }

  static renderDoc(data) {
    const doc = new Document();
    this.renderTitlePage(doc, data);
    this.renderSources(doc, data);
    console.log('this.doc', doc);

    return doc;
  }
}

export default ExportDocxProfile;