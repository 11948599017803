import React, { memo, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import AuditAction from "../../../../store/audit/auditAction";
import { FilterButton } from "../../../../shared-components/Buttons/HeaderActionButtons/FilterButton";
import styled from "styled-components";

export const ExtUtilityButtons = memo(() => {
  const path = useLocation().pathname;
  const dispatch = useDispatch();
  const toggleAuditFilter = useCallback(
    () => dispatch(AuditAction.toggleFilter()),
    [dispatch]
  );
  const toggleAudit = () => toggleAuditFilter();

  return (
    <>
      <Container>
        {path === "/users/audit" && <FilterButton clicked={toggleAudit} />}
      </Container>
    </>
  );
});

const Container = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: 1280px) {
    display: none;
  }
`;
