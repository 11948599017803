import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routing from "./Routing";
import PopUpContainer from "../shared-components/PopUp/PopUpContainer";
import LanguageWrapper from "./LanguageWrapper";
import { hot } from "react-hot-loader";
import { ErrorBoundary } from "../utils/errorBoundary";
import { Notifications } from "../shared-components/UI/NotificationsPortal";

import { GlobalStyle } from "./styled";

const App = () => (
  <BrowserRouter>
    <LanguageWrapper>
      <GlobalStyle />
        <ErrorBoundary component="App">
          <>
          <Routing />
          <Notifications />
          </>
        </ErrorBoundary>
      <PopUpContainer />
    </LanguageWrapper>
  </BrowserRouter>
);

export default hot(module)(App);
