import React, { memo } from "react";
import * as Styled from "./styled";
import { Transition } from "react-transition-group";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

const DropDown = memo(
  withTranslation()(({ t, isShown, showPopup, match }) => {
    return (
      <Transition in={isShown} timeout={200} mountOnEnter unmountOnExit>
        {(state) => (
          <Styled.Container
            style={{
              ...defaultDropDown,
              ...transitionDropDown[state],
            }}
          >
            <Styled.UserLink
              to="/events"
              name="Search"
              isactive={match.path === "/events" ? true : false}
            >
              {t("Search")}
            </Styled.UserLink>
            <Styled.UserLink
              to="/query"
              name="Query"
              isactive={match.path === "/query" ? true : false}
            >
              {t("Query")}
            </Styled.UserLink>
          </Styled.Container>
        )}
      </Transition>
    );
  })
);

export default withRouter(DropDown);

const defaultDropDown = {
  opacity: 0,
  transition: `all 200ms ease`,
};
const transitionDropDown = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0 },
};
