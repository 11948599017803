import styled from "styled-components";
import { themes } from "../../../../config/Themes";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  justify-content: flex-end;

  @media (max-width: 1280px) {
    flex-grow: 0;
  }

  @media (max-width: 1020px) {
    position: relative;
    order: 10;
    height: 56px;
    padding: 0 10px;
  }
`;

export const More = styled.button`
  border: none;
  color: ${themes.regular.white};
  background: none;

  :focus {
    outline: none;
  }
`;