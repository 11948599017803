import { put, takeEvery } from "redux-saga/effects";
import UsersAction from "./usersAction";
import RootScopeAction from "../rootScope/rootScopeAction";
import config from "../../config/config";

import { apiUsersService } from "../../config/api";

function* getUserListSaga(action) {
  const {
    pageNumber = 0,
    pageSize = config.usersPageSize,
    //sortBy = 'username',
    //sortDir = 'ASC'
  } = action.payload;
  yield put(UsersAction.setUsersLoading(true));

  try {
    //const data = yield apiUsersService.getUsers({page: pageNumber, size: pageSize });
    //const data = yield axios.get(`${config.api_user}?page=${pageNumber}&size=${pageSize}`, { headers: authHeader()})
    const data = yield apiUsersService.getUsers({page: pageNumber, size: pageSize });
    console.log('API', data, action, {page: pageNumber, size: pageSize});
    yield put(UsersAction.setUserList(data));
    yield put(UsersAction.setUsersLoading(false));
  } catch (e) {
    yield put(RootScopeAction.handleAPIError(e));
    yield put(UsersAction.setUsersLoading(false));
  }
}

function* createUserSaga(action) {
  const data = {
    userName: action.payload.userName,
    roles: action.payload.roles,
    password: action.payload.password
  };
  const {currentPage = 0} = action.payload;

  try {
    //yield axios.post(`${config.api_users}`, data, { headers: authHeader()})
    yield apiUsersService.registerUser(data);
    yield put(UsersAction.getUserList({pageNumber: currentPage}));
  } catch (e) {
    yield put(RootScopeAction.handleAPIError(e));
  }
}

function* editUserSaga(action) {
  const data = {
    userName: action.payload.userName,
    roles: action.payload.roles,
    password: action.payload.password
  };
  const { id, currentPage = 0 } = action.payload;

  try {
    //yield axios.post(`${config.api_users}/${id}`, data, { headers: authHeader()})
    yield apiUsersService.updateUser(id, data);
    yield put(UsersAction.getUserList({pageNumber: currentPage}));
  } catch (e) {
    yield put(RootScopeAction.handleAPIError(e));
  }
}

function* deleteUserSaga(action) {
  const { id, currentPage = 0 } = action.payload;

  try {
    //yield axios.delete(`${config.api_users}/${id}`, { headers: authHeader()})
    yield apiUsersService.deleteUser(id);
    yield put(UsersAction.getUserList({pageNumber: currentPage}));
  } catch (e) {
    yield put(RootScopeAction.handleAPIError(e));
  }
}

export function* watchUsers() {
  yield takeEvery(UsersAction.GET_USER_LIST, getUserListSaga);
  yield takeEvery(UsersAction.CREATE_USER, createUserSaga);
  yield takeEvery(UsersAction.EDIT_USER, editUserSaga);
  yield takeEvery(UsersAction.DELETE_USER, deleteUserSaga);
}
