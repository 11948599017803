import ActionUtility from "../ActionUtility";

export default class RootScopeAction {
  
  static UPDATE_STATE = "RootScopeAction.UPDATE_STATE";
  static SHOW_NOTIFICATION = "RootScopeAction.SHOW_NOTIFICATION";
  static CLEAR_NOTIFICATIONS = "RootScopeAction.CLEAR_NOTIFICATIONS";
  static HANDLE_API_ERROR = "RootScopeAction.HANDLE_API_ERROR";

  static updateState(fieldsToUpdate) {
    return ActionUtility.createAction(RootScopeAction.UPDATE_STATE, fieldsToUpdate);
  }

  static showNotification(message) {
    return ActionUtility.createAction(RootScopeAction.SHOW_NOTIFICATION, message);
  }

  static clearNotifications() {
    return ActionUtility.createAction(RootScopeAction.CLEAR_NOTIFICATIONS);
  }

  static handleAPIError(error) {
    return ActionUtility.createAction(RootScopeAction.HANDLE_API_ERROR, error);
  }
}
