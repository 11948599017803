import ActionUtility from "../ActionUtility";

export default class AuthAction {

  static LOGIN_INIT = "AuthAction.LOGIN_INIT";
  static LOGIN_ERROR = "AuthAction.LOGIN_ERROR";
  static LOGIN_SUCCESS = "AuthAction.LOGIN_SUCCESS";
  static LOGOUT = "AuthAction.LOGOUT";
  static VERIFY_TOKEN = "AuthAction.VERIFY_TOKEN";
  static SET_CREDENTIALS = "AuthAction.SET_CREDENTIALS";
  static CLEAR_CREDENTIALS = "AuthAction.CLEAR_CREDENTIALS";
  static GET_CREDENTIALS = "AuthAction.GET_CREDENTIALS";
  static HANDLE_CHANGE_LOCATION = "AuthAction.HANDLE_CHANGE_LOCATION";

  static loginInit(username, password) {
    return ActionUtility.createAction(AuthAction.LOGIN_INIT, { username, password });
  }

  static loginError(error, errorMessage) {
    return ActionUtility.createAction(AuthAction.LOGIN_ERROR, { error, errorMessage });
  }

  static loginSuccess(userData) {
    return ActionUtility.createAction(AuthAction.LOGIN_SUCCESS, userData);
  }

  static logout() {
    return ActionUtility.createAction(AuthAction.LOGOUT);
  }

  static verifyToken(username, token) {
    return ActionUtility.createAction(AuthAction.VERIFY_TOKEN, { username, token });
  }

  static setCredentials(response) {
    return ActionUtility.createAction(AuthAction.SET_CREDENTIALS, response);
  }

  static clearCredentials() {
    return ActionUtility.createAction(AuthAction.CLEAR_CREDENTIALS);
  }

  static getCredentials(callback) {
    return ActionUtility.createAction(AuthAction.GET_CREDENTIALS, callback);
  }

  static handleChangeLocation(credentials, currLocation) {
    return ActionUtility.createAction(AuthAction.HANDLE_CHANGE_LOCATION, { credentials, currLocation } );
  }
}