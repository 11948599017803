import PopupReducer from "./popup/popupReducer";
import SettingsReducer from "./userSettings/userSettingsReducer";
import UsersReducer from "./users/usersReducer";
import AuthReducer from "./auth/authReducer";
import RootScopeReducer from "./rootScope/rootScopeReducer";
import JobsReducer from "./jobs/jobsReducer";
import ProfilesReducer from "./profiles/profilesReducer";
import AuditReducer from "./audit/auditReducer";

export const reducers = {
  popup : new PopupReducer().reducer,
  settings: new SettingsReducer().reducer,
  users: new UsersReducer().reducer,
  auth: new AuthReducer().reducer,
  rootScope: new RootScopeReducer().reducer,
  jobs: new JobsReducer().reducer,
  profiles: new ProfilesReducer().reducer,
  audit: new AuditReducer().reducer,
}