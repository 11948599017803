import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en";
import uz from "./locales/uz";
import uz_cyrl from "./locales/uz_cyrl";
import ru from "./locales/ru";

const resources = {
  en,
  uz,
  uz_cyrl,
  ru,
}

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("webint_lang"), //TODO: should be result of user's choice
  fallbackLng: "ru",
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
}, () => console.log(" i18n init done"));

export default i18n;