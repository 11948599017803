export const selectProfilesList = (state) =>
  state.profiles.profiles;

export const selectProfilesLoading = (state) =>
    state.profiles.loading;

export const selectProfilesFilter = state => state.profiles.filters;
export const selectProfilesIsFiltered = state => state.profiles.isFiltered;
export const selectProfilesIsImageMode = state => state.profiles.imageMode;
export const selectProfilesImageSearchDone = state => state.profiles.imageSearchDone;
export const selectProfilesCurrentImage = state => state.profiles.currentImage;
export const selectProfilesCurrentImageFile = state => state.profiles.currentImageFile;
export const selectProfilesCurrentImageLoading = state => state.profiles.currentImageLoading;
export const selectProfilesCurrentImageResults = state => state.profiles.currentImageResults;
//export const selectProfilesImageProfiles = state => state.profiles.imageProfiles;

export const selectCurrentProfile = state => state.profiles.currentProfile;
export const selectLoadingCurrentProfile = state => state.profiles.loadingCurrentProfile;

export const selectSelectedPhones = state => state.profiles.selectedPhones;
export const selectSelectFiltersMode = state => state.profiles.selectFiltersMode;
export const selectSelectedFilters = state => state.profiles.selectedFilters;
export const selectExportLoading = state => state.profiles.exportLoading;
export const selectExportProfiles = state => state.profiles.exportProfiles;

export const selectProfilesFilterFiles = state => state.profiles.filterFiles;
export const selectProfilesFilterLists = state => state.profiles.filterLists;
export const selectProfilesFilterListMode = state => state.profiles.filterListMode;