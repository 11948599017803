import React, { Component } from "react";
import { ErrorBoundaryCover } from "../shared-components/UI/ErrorBoundaryCover";

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    console.log("error caught by error boundary: ", error);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    console.log(error);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorBoundaryCover error={this.state.errorInfo} component={this.props.component} />
    } else {
      return this.props.children;
    }
  }
}

