import i18next from "i18next";

const AuditTypes = [
  {value: "AUDIT", label: i18next.t("Audit")},
  {value: "LOGIN", label: i18next.t("Login")},
  {value: "LOGOUT", label: i18next.t("Logout")},
  {value: "JOB", label: i18next.t("Job")},
  {value: "PROFILE", label: i18next.t("Profile")},
  //{value: "IMAGE", label: i18next.t("Image")},
  {value: "CREATE_JOB", label: i18next.t("Create Job")},
  {value: "CREATE_RANGE_JOB", label: i18next.t("Create Job by Range")},
  //{value: "IMAGE_CLEAR_STORAGE", label: i18next.t("Clear Image Storage")},
  {value: "USER", label: i18next.t("User")},
]

const UserAuditTypes = [
  {value: "AUDIT", label: i18next.t("Audit")},
  {value: "LOGIN", label: i18next.t("Login")},
  {value: "LOGOUT", label: i18next.t("Logout")},
  {value: "PROFILE", label: i18next.t("Profile")},
  //{value: "IMAGE", label: i18next.t("Image")},
]

export {AuditTypes, UserAuditTypes};