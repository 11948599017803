import { put, takeEvery, select, delay, putResolve } from "redux-saga/effects";
import jwt from "jsonwebtoken";
import AuthAction from "./authAction";
import RootScopeAction from "../rootScope/rootScopeAction";
import { SupportService } from "../../services/support";
import {apiAuthService} from "../../config/api";
import AppAction from "../app/appAction";
import {getCurrentUser} from "../rootScope/rootScopeSelector";

function* loginSaga(action) {
  const { username, password } = action.payload;
  const requestBody = {
    userName: username,
    password,
  };

  yield put(RootScopeAction.updateState({ dataLoading: true }));

  try {
    const {data} = yield apiAuthService.authenticateUser(requestBody);

    yield put(AuthAction.loginSuccess(data));
    yield put(RootScopeAction.updateState({ dataLoading: false }));
  } catch (e) {
    yield put(AuthAction.loginError(true, "Incorrect username or password"));
    yield put(
      RootScopeAction.showNotification({
        text: "Incorrect username or password",
        type: "error",
        duration: 5000,
      })
    );
    yield put(RootScopeAction.updateState({ dataLoading: false }));
  }
}

function* loginSuccessSaga(action) {
  const lastLocation = sessionStorage.getItem("redirect");
  console.log('lastLocation from session', lastLocation);

  const {accessToken} = action.payload;
  yield localStorage.setItem('auth', accessToken);
  try {
    const currentUser = yield jwt.decode(accessToken);
    /*console.log('currentUser', currentUser, lastLocation);*/
    /*yield put(RootScopeAction.updateState({ currentUser }));*/
    const {sub, roles} = currentUser;
    yield put(
      RootScopeAction.updateState({
        currentUser: {username: sub, roles: roles.split(',')},
      })
    );

    if (lastLocation) {
      yield sessionStorage.setItem("redirect", "");
      if (currentUser) {
        if (
          (SupportService.validateRoleAdmin(currentUser) && lastLocation.indexOf('/ext') === 0) ||
          (SupportService.validateRoleUser(currentUser) && lastLocation.indexOf('/int') === 0)
        ) {
          yield put(RootScopeAction.updateState({ lastLocation: lastLocation }));
        } else {
          if (SupportService.validateRoleAdmin(currentUser)) {
            yield put(RootScopeAction.updateState({ lastLocation: "/ext" }));
          } else if (SupportService.validateRoleUser(currentUser)) {
            yield put(RootScopeAction.updateState({ lastLocation: "/int" }));
          }
        }
      }
    } else {
      if (currentUser) {
        if (SupportService.validateRoleAdmin(currentUser)) {
          yield put(RootScopeAction.updateState({ lastLocation: "/ext" }));
        } else if (SupportService.validateRoleUser(currentUser)) {
          yield put(RootScopeAction.updateState({ lastLocation: "/int" }));
        }
      }
    }
  } catch (e) {
    yield put(AuthAction.loginError(true, e.message));
    yield put(RootScopeAction.updateState({ dataLoading: false }));
  }
}

function* logoutSaga(action) {
  try {
    const {username = ''} = yield select(getCurrentUser);
    //yield axios.get(`${config_data.CONFIG.PROD.GENIUS_API}/logout`);
    //localStorage.setItem("logout", "true");
    yield apiAuthService.logout(username);
    yield put(AuthAction.clearCredentials());
    yield put(AppAction.resetStore());
    yield put(RootScopeAction.updateState({ lastLocation: "/login" }));
    if (action && action.payload) {
      const {currentLocation} = action.payload;
      if (currentLocation) {
        yield sessionStorage.setItem("redirect", currentLocation);
      }
    }
  } catch (e) {
    console.log(`Logout error: ${e}`);
    yield put(
      RootScopeAction.showNotification({
        text: "Logout error",
        type: "error",
        duration: 5000,
      })
    );
  }
}

/*function* verifyTokenSaga(action) {
  try {
    yield jwt.verify(action.payload.token, jwtSecret);
    yield put(AuthAction.loginSuccess(action.payload.userId));
  } catch (e) {
    yield put(AuthAction.loginError(true, e.message));
  }
}*/

function* setCredentialsSaga(action) {
  /*const {accessToken} = action.payload;
  localStorage.setItem('auth', accessToken);
  try {
    const {sub, roles} = yield jwt.decode(accessToken);
    yield put(
       RootScopeAction.updateState({
         currentUser: {username: sub, roles: [roles]},
       })
    );
  } catch (e) {
    yield put(AuthAction.loginError(true, e.message));
  }
  const cu = yield select(getRootScope);
  console.log('cu1', cu);*/

  /*const {username, roles = []} = action.payload;
  console.log('setCredentialsSaga', username, roles);
  const storeToSession = {...response, isAuth: true};
  window.sessionStorage.setItem("auth", JSON.stringify(storeToSession));
  yield put(
    RootScopeAction.updateState({
      currentUser: {username, roles},
    })
  );*/
}

function* clearCredentialsSaga(action) {
  yield put(RootScopeAction.updateState({ globals: {}, currentUser: {}}));
  localStorage.removeItem("auth");
}

function* getCredentialsSaga(action) {
  const callback = action.payload;
  const accessToken = localStorage.getItem("auth");
  if (accessToken) {
    const {sub, roles} = yield jwt.decode(accessToken);
    yield put(
      RootScopeAction.updateState({
        currentUser: {username: sub, roles: roles.split(',')},
      })
    );
    callback({sub, roles});
  } else {
    /*const response = { success: false };
    response.message = "Session is expired";
    yield put(AuthAction.logout());
    callback(response);*/
    yield put(RootScopeAction.updateState({
      lastLocation: "/login",
    }));
  }
}

function* handleChangeLocationSaga(action) {
  /*return false;
  const { credentials, currLocation } = action.payload;
  console.log("creds from handleChangeLocation", credentials);
  yield put(RootScopeAction.updateState({ globals: credentials }));
  let rootScopeState = yield select(getRootScope);
  if (rootScopeState.globals.currentUser) {
    yield put(RootScopeAction.updateState({
      isAdmin: SupportService.validateRoleAdmin(rootScopeState.globals.currentUser),
      globals: {
        currentUser: {
          icon: rootScopeState.isAdmin ? "admin" : "user",
        },
      },
    }));

    try {
      // TODO: getCurrentUserQuota
    } catch (e) {
      console.log("error", e);
    }
  }

  if (
    rootScopeState.lastLocation &&
    !rootScopeState.lastLocation.contains("/login") &&
    !rootScopeState.globals.currentUser
  ) {
    window.sessionStorage.setItem("redirect", currLocation);
    yield put(RootScopeAction.updateState({
      lastLocation: "/login",
    }));
  } else {
    yield put(RootScopeAction.updateState({
      lastLocation: "/investigation",
    }));
  }*/
}

export function* watchAuth() {
  yield takeEvery(AuthAction.LOGIN_INIT, loginSaga);
  yield takeEvery(AuthAction.LOGIN_SUCCESS, loginSuccessSaga);
  yield takeEvery(AuthAction.LOGOUT, logoutSaga);
  //yield takeEvery(AuthAction.VERIFY_TOKEN, verifyTokenSaga);
  //yield takeEvery(AuthAction.SET_CREDENTIALS, setCredentialsSaga);
  yield takeEvery(AuthAction.CLEAR_CREDENTIALS, clearCredentialsSaga);
  yield takeEvery(AuthAction.GET_CREDENTIALS, getCredentialsSaga);
  //yield takeEvery(AuthAction.HANDLE_CHANGE_LOCATION, handleChangeLocationSaga);
}
