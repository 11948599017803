import styled from "styled-components";
import { themes } from "../../../../config/Themes";
import { Link } from "react-router-dom";

export const Button = styled.div`
  width: 26px;
  height: 26px;
  margin: 1px 4px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${(props) => props.img});
  transition: all 250ms ease;
  position: relative;

  :hover {
    cursor: pointer;
    // opacity: 0.7;
  }

  @media (max-width: 1020px) {
    width: 100%;
    margin: 2px 0;
    background-position: left center;
    background-size: 20px;
    padding-left: 30px;
    box-sizing: border-box;
    font-size: 14px;
    color: ${themes.regular.medium_grey};
    display: flex;
    align-items: center;
  }
`;

export const Label = styled.label`
  display: block;
  width: 26px;
  height: 30px;
  margin: 5px;
  background: url(${(props) => props.img}) no-repeat center center;
  background-size: contain;
  transition: all 250ms ease;

  :hover {
    cursor: pointer;
    opacity: 0.7;
  }

  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
  }

  @media (max-width: 1020px) {
    width: 100%;
    margin: 2px 0;
    background-position: left center;
    background-size: 20px;
    padding-left: 30px;
    box-sizing: border-box;
    font-size: 14px;
    color: ${themes.regular.medium_grey};
    display: flex;
    align-items: center;
  }
`;

export const OptionsWrapper = styled.div`
  padding-top: 5px;
  position: absolute;
  z-index: 1000;
  width: 120px;
  left: 0;
  top: 25px;
`

export const ExportOptions = styled.div`
  display: flex;
  flex-flow: column;
  padding: .5em;
  border-radius: 5px;
  background: ${themes.regular.light_blue_bg};
  box-shadow: 1px 2px 5px ${themes.regular.medium_grey};

  :hover {
    opacity: 1;
`;

export const Option = styled(Link)`
  font-size: 1em;
  color: ${themes.regular.white};
  margin: 0;
  padding: .5em 0;
  padding-left: .8em;
  white-space: nowrap;

  :hover {
    cursor: pointer;
    color: ${themes.regular.dark_blue};
  }
`;

