import React, { memo, Suspense, useEffect, useCallback } from "react";
import { Switch, Redirect, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UserSettingsAction from "../store/userSettings/userSettingsAction";
import AuthAction from "../store/auth/authAction";
import {getLastLocation} from "../store/rootScope/rootScopeSelector";
import {ROLES, ROUTES} from "../config/Routes";
import ExtRoute from "./ExtRoute";
import AppRoute from "./AppRoute";
import ExtLayout from "../layouts/ExtLayout";
import LoginLayout from "../layouts/LoginLayout";
import NotFoundPage from "../pages/NotFoundPage/index";
import Loading from "../shared-components/UI/Loading";
import IntRoute from "./IntRoute";
import PopupAction from "../store/popup/popupAction";

function Waiting(Component) {
  return (props) => (
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  );
}

export default memo(() => {
  const lastLocation = useSelector(getLastLocation);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const setActiveRoute = useCallback(
    (route) => {
      dispatch(PopupAction.closePopup());
      dispatch(UserSettingsAction.setActiveRoute(route))
    },
    [dispatch]
  );
  const getCredentials = useCallback(
    (cb) => dispatch(AuthAction.getCredentials(cb)),
    [dispatch]
  );

  useEffect(() => {
    getCredentials((data) => {}/*console.log("get creds", data)*/);
    return () => null;
  }, []);
  useEffect(() => {
      if (location.pathname && location.pathname.indexOf('login') === -1) {
        sessionStorage.setItem('redirect', location.pathname);
      }
      setActiveRoute(location.pathname);
    },[location.pathname, setActiveRoute]
  );

  useEffect(() => {
      //console.log('Routing.lastLocation', lastLocation);
      history.push(lastLocation);
    },[lastLocation, history]
  );
  useEffect(() => {
    const handleInvalidToken = e => {
      if (e.key === 'auth' && e.oldValue && !e.newValue) {
        dispatch(AuthAction.logout())
      }
    }
    window.addEventListener('storage', handleInvalidToken)
    return function cleanup() {
      window.removeEventListener('storage', handleInvalidToken)
    }
  }, [dispatch])
  //useEffect(() => console.log("location change ", location.pathname), [location]);

  return (
    <>
      <Switch>
        <Redirect exact from="/" to="/ext" />
        {
          ROUTES.map(
            ({
               location,
               needRole,
               component,
               layout = LoginLayout
             },
             key
            ) => {
            switch (needRole) {
              case ROLES.admin: return (
                  <ExtRoute
                      key={key}
                      exact
                      path={location.pathname}
                      component={Waiting(component)}
                      layout={layout}
                  />
              )
              /*case ROLES.user: return (
                  <IntRoute
                      key={key}
                      exact
                      path={location.pathname}
                      component={Waiting(component)}
                      layout={layout}
                  />
              )*/
              default: return (
                  <AppRoute
                      key={key}
                      exact
                      path={location.pathname}
                      component={Waiting(component)}
                      layout={layout}
                  />
              )
            }
          }
        )}
        <ExtRoute
          path="*"
          component={Waiting(NotFoundPage)}
          layout={ExtLayout}
        />
      </Switch>
    </>
  );
});
