import {globals} from './globals';

const points = {
  api_jobs: globals.api_url + "/job",
  api_user: globals.api_url + "/user",
  api_websocket: globals.api_base_path + "/mm-websocket",
  api_image: globals.api_url + "/image",
  api_profiles: globals.api_url + "/profile",
};

const shared = {
  roles: {
    admin_roles: ["ADMIN"],
    user_roles: ["USER"],
  },
  profilesPageSize: process.env.REACT_APP_PROFILES_PAGE_SIZE ? process.env.REACT_APP_PROFILES_PAGE_SIZE * 1 : 15,
  usersPageSize: process.env.REACT_APP_USERS_PAGE_SIZE ? process.env.REACT_APP_USERS_PAGE_SIZE * 1 : 15,
  jobPageSize: process.env.REACT_APP_JOB_PAGE_SIZE ? process.env.REACT_APP_JOB_PAGE_SIZE * 1 : 15,
  jobPhonesPageSize: process.env.REACT_APP_JOB_PHONES_PAGE_SIZE ? process.env.REACT_APP_JOB_PHONES_PAGE_SIZE * 1 : 39,
  jobPhonesDisplay: process.env.REACT_APP_JOB_PHONES_DISPLAY ? (process.env.REACT_APP_JOB_PHONES_DISPLAY === 'true') : true,
  maxPhonesFileSize: process.env.REACT_APP_MAX_PHONES_FILE_SIZE
    ? window.Function('"use strict";return (' + process.env.REACT_APP_MAX_PHONES_FILE_SIZE + ')')()
    : 500 * 1024 * 1024,
  maxPhonesFileNumber: process.env.REACT_APP_MAX_PHONES_FILE_NUMBER || 10000,
  maxExportProfilesLimit: process.env.REACT_APP_MAX_EXPORT_PROFILES_LIMIT ? process.env.REACT_APP_MAX_EXPORT_PROFILES_LIMIT * 1 : 1000,
  maxExportAuditLimit: process.env.REACT_APP_MAX_EXPORT_AUDIT_LIMIT ? process.env.REACT_APP_MAX_EXPORT_AUDIT_LIMIT * 1 : 500,
  maxPhoneRange: process.env.REACT_APP_MAX_PHONE_RANGE ? process.env.REACT_APP_MAX_PHONE_RANGE * 1 : 100,
  maxProfilesSelection: process.env.REACT_APP_MAX_PROFILES_SELECTION ? process.env.REACT_APP_MAX_PROFILES_SELECTION * 1 : 1000,
  mainJobStoppable: process.env.REACT_APP_MAIN_JOB_STOPPABLE ? (process.env.REACT_APP_MAIN_JOB_STOPPABLE === 'true') : false,
  maxBulkSearchCriteria: process.env.REACT_APP_MAX_BULK_SEARCH_CRITERIA ? process.env.REACT_APP_MAX_BULK_SEARCH_CRITERIA * 1 : 100,
};

const AppConfig = { ...globals, ...points, ...shared };

export default AppConfig;