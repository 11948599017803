import ActionUtility from "../ActionUtility";

export default class AuditAction {
  static GET_AUDIT_LIST = "AuditAction.GET_AUDIT_LIST";
  static SET_AUDIT_LIST = "AuditAction.SET_AUDIT_LIST";
  static SET_AUDIT_LOADING = "AuditAction.SET_AUDIT_LOADING";
  static SET_AUDIT_FILTER = "AuditAction.SET_AUDIT_FILTER";
  static CLEAR_AUDIT_FILTER = "AuditAction.CLEAR_AUDIT_FILTER";
  static UPDATE_AUDIT_STATE = "AuditAction.UPDATE_AUDIT_STATE";

  static EXPORT_AUDIT_LIST = "AuditAction.EXPORT_AUDIT_LIST";
  static EXPORT_AUDIT_LOADING = "AuditAction.EXPORT_AUDIT_LOADING";

  static getAuditList() {
    return ActionUtility.createAction(AuditAction.GET_AUDIT_LIST);
  }

  static setAuditList(data) {
    return ActionUtility.createAction(AuditAction.SET_AUDIT_LIST, data);
  }

  static setAuditLoading(state) {
    return ActionUtility.createAction(AuditAction.SET_AUDIT_LOADING, state);
  }

  static setAuditFilter(state) {
    return ActionUtility.createAction(AuditAction.SET_AUDIT_FILTER, state);
  }
  static clearAuditFilter(state) {
    return ActionUtility.createAction(AuditAction.CLEAR_AUDIT_FILTER, state);
  }
  static updateAuditState(fields) {
    return ActionUtility.createAction(AuditAction.UPDATE_AUDIT_STATE, fields);
  }
  static exportAuditList(type) {
    return ActionUtility.createAction(AuditAction.EXPORT_AUDIT_LIST, type);
  }
  static exportAuditLoading(loading) {
    return ActionUtility.createAction(AuditAction.EXPORT_AUDIT_LOADING, loading);
  }
}
