import ActionUtility from "../ActionUtility";

export default class ProfilesAction {
  static GET_PROFILES_LIST = "ProfilesAction.GET_PROFILES_LIST";
  static SET_PROFILES_LIST = "ProfilesAction.SET_PROFILES_LIST";
  static SET_PROFILES_LOADING = "ProfilesAction.SET_PROFILES_LOADING";
  static SET_PROFILES_FILTER = "ProfilesAction.SET_PROFILES_FILTER";
  static CLEAR_PROFILES_FILTER = "ProfilesAction.CLEAR_PROFILES_FILTER";
  static GET_PROFILE = "ProfilesAction.GET_PROFILE";
  static UPDATE_PROFILES_STATE = "ProfilesAction.UPDATE_PROFILES_STATE";
  static SEARCH_BY_IMAGE = "ProfilesAction.SEARCH_BY_IMAGE";

  static EXPORT_PROFILES_LIST = "ProfilesAction.EXPORT_PROFILES_LIST";
  static EXPORT_PROFILES_LOADING = "ProfilesAction.EXPORT_PROFILES_LOADING";
  static EXPORT_PROFILE = "ProfilesAction.EXPORT_PROFILE";

  static SET_SELECT_PHONES = "ProfilesAction.SET_SELECT_PHONES";
  static CLEAR_SELECT_PHONES = "ProfilesAction.CLEAR_SELECT_PHONES";

  static SET_SELECT_FILTERS_MODE = "ProfilesAction.SET_SELECT_FILTERS_MODE";
  static SET_SELECT_FILTERS = "ProfilesAction.SET_SELECT_FILTERS";
  static CLEAR_SELECT_FILTERS = "ProfilesAction.CLEAR_SELECT_FILTERS";

  static SET_FILTER_FILE = "ProfilesAction.SET_FILTER_FILES";
  static REMOVE_FILTER_FILE = "ProfilesAction.REMOVE_FILTER_FILE";
  static CLEAR_FILTER_FILES = "ProfilesAction.CLEAR_FILTER_FILES";

  static SET_FILTER_LIST = "ProfilesAction.SET_FILTER_LIST";
  static REMOVE_FILTER_LIST = "ProfilesAction.REMOVE_FILTER_LIST";
  static CLEAR_FILTER_LISTS = "ProfilesAction.CLEAR_FILTER_LISTS";



  static getProfilesList() {
    return ActionUtility.createAction(ProfilesAction.GET_PROFILES_LIST);
  }

  static setProfilesList(data) {
    return ActionUtility.createAction(ProfilesAction.SET_PROFILES_LIST, data);
  }

  static setProfilesLoading(state) {
    return ActionUtility.createAction(ProfilesAction.SET_PROFILES_LOADING, state);
  }

  static setProfilesFilter(state) {
    return ActionUtility.createAction(ProfilesAction.SET_PROFILES_FILTER, state);
  }
  static clearProfilesFilter(state) {
    return ActionUtility.createAction(ProfilesAction.CLEAR_PROFILES_FILTER, state);
  }

  static getProfile(phone) {
    return ActionUtility.createAction(ProfilesAction.GET_PROFILE, phone);
  }

  static updateProfilesState(fields) {
    return ActionUtility.createAction(ProfilesAction.UPDATE_PROFILES_STATE, fields);
  }

  static searchByImage(file, filters) {
    return ActionUtility.createAction(ProfilesAction.SEARCH_BY_IMAGE, {file, filters});
  }

  static exportProfilesList(type) {
    return ActionUtility.createAction(ProfilesAction.EXPORT_PROFILES_LIST, type);
  }
  static exportProfilesLoading(loading) {
    return ActionUtility.createAction(ProfilesAction.EXPORT_PROFILES_LOADING, loading);
  }

  static exportProfile(type, data) {
    return ActionUtility.createAction(ProfilesAction.EXPORT_PROFILE, {type, data});
  }

  static setSelectPhones(phones, selected) {
    return ActionUtility.createAction(ProfilesAction.SET_SELECT_PHONES, {phones, selected});
  }
  static clearSelectPhones() {
    return ActionUtility.createAction(ProfilesAction.CLEAR_SELECT_PHONES);
  }

  static setSelectFiltersMode(mode) {
    return ActionUtility.createAction(ProfilesAction.SET_SELECT_FILTERS_MODE, mode);
  }
  static setSelectFilters(filters, selected) {
    return ActionUtility.createAction(ProfilesAction.SET_SELECT_FILTERS, {filters, selected});
  }
  static clearSelectFilters() {
    return ActionUtility.createAction(ProfilesAction.CLEAR_SELECT_FILTERS);
  }

  static setFilterFile(field, fileObj) {
    return ActionUtility.createAction(ProfilesAction.SET_FILTER_FILE, {field, fileObj});
  }
  static removeFilterFile(field) {
    return ActionUtility.createAction(ProfilesAction.REMOVE_FILTER_FILE, field);
  }
  static clearFilterFiles() {
    return ActionUtility.createAction(ProfilesAction.CLEAR_FILTER_FILES);
  }

  static setFilterList(field, listObj) {
    return ActionUtility.createAction(ProfilesAction.SET_FILTER_LIST, {field, listObj});
  }
  static removeFilterList(field) {
    return ActionUtility.createAction(ProfilesAction.REMOVE_FILTER_LIST, field);
  }
  static clearFilterLists() {
    return ActionUtility.createAction(ProfilesAction.CLEAR_FILTER_LISTS);
  }
}