import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import * as Styled from "./styled";
import {ROLES, ROUTES} from "../../../../config/Routes";
import { withTranslation } from "react-i18next";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SearchTabDropDown from "./SearchTabDropDown";

const MainMenu = withTranslation()(({ t, match }) => {
  const [isShownDropDown, setShownDropDown] = useState(false);

  const showDropDown = () => setShownDropDown(true);
  const hideDropDown = () => setShownDropDown(false);
  return (
    <Styled.HeaderNavMenu>
      <Styled.NavMenu>
        {ROUTES.filter((route) => route.needRole === ROLES.admin && route.mainMenu).map(
          ({ location, name, nameToDisplay }, key) => (
            <Styled.NavMenuItem
              active={match.path === location.pathname}
              key={key}
            >
              <Styled.MenuLink to={location}>
                <i className="icon icon_folder" />
                {String(nameToDisplay)}
              </Styled.MenuLink>
            </Styled.NavMenuItem>
          )
        )}
      </Styled.NavMenu>
    </Styled.HeaderNavMenu>
  );
});

export default withRouter(MainMenu);
