import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font, Image, Link } from '@react-pdf/renderer';
import {withTranslation} from "react-i18next";
import font from "../../../../assets/fonts/arial-unicode-ms.ttf";
import {SupportService} from "../../../support";
import OnlineIcon from "../../../../assets/img/online.png";
import {EXT_TITLE, VERSION} from "../../../../config/consts";
import moment from "moment";
import {AuditTypes} from "../../../../pages/Ext/Audit/const";

Font.register({
  family: 'Roboto',
  src: font
});
Font.registerEmojiSource({
  format: 'png',
  url: 'https://twemoji.maxcdn.com/2/72x72/',
});

const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'
const COL_DATE = 20;
const COL_TYPE = 10;
const COL_USER = 10;
const COL_DETAILS = 20;
const COL_ARGS = 40;

const HEADER_STYLES = {
  borderStyle: BORDER_STYLE,
  borderColor: BORDER_COLOR,
  borderBottomColor: '#000',
  borderWidth: 1,
  borderLeftWidth: 0,
  borderTopWidth: 0,
  fontFamily: "Roboto"
}
const COL_STYLES = {
  borderStyle: BORDER_STYLE,
  borderColor: BORDER_COLOR,
  borderWidth: 1,
  borderLeftWidth: 0,
  borderTopWidth: 0,
  fontFamily: "Roboto"
}

const socialIcon = (network) => {
  const images = require.context('../../../../assets/img/social', true);
  try{
    let img = images('./' + network.toLowerCase() + '.png');
    return (
      <>
      <Image style={{margin: 150}} key={network + Math.random()} alt={network} title={network} src={img} />
      <Text> </Text>
      </>
    );
  } catch (e) {
    return <Text> </Text>;
  }
};

const styles = StyleSheet.create({
  body: {
    padding: 30,
    fontSize: 10,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row"
  },
  tableColDateHeader: {
    width: COL_DATE + '%',
    ...HEADER_STYLES
  },
  tableColTypeHeader: {
    width: COL_TYPE + "%",
    ...HEADER_STYLES
  },
  tableColUserHeader: {
    width: COL_USER + "%",
    ...HEADER_STYLES
  },
  tableColDetailsHeader: {
    width: COL_DETAILS + "%",
    ...HEADER_STYLES
  },
  tableColArgsHeader: {
    width: COL_ARGS + "%",
    ...HEADER_STYLES
  },
  tableColDate: {
    width: COL_DATE + '%',
    ...COL_STYLES
  },
  tableColType: {
    width: COL_TYPE + '%',
    ...COL_STYLES
  },
  tableColUser: {
    width: COL_USER + '%',
    ...COL_STYLES
  },
  tableColDetails: {
    width: COL_DETAILS + '%',
    ...COL_STYLES
  },
  tableColArgs: {
    width: COL_ARGS + '%',
    wordBreak: 'break-all',
    ...COL_STYLES
  },

  tableCellHeader: {
    margin: 5,
    fontSize: 10,
    fontWeight: 500
  },
  tableCell: {
    margin: 5,
    fontSize: 10
  },
  header: {
    fontSize: 10,
    marginBottom: 10,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

const ExportPdfAudit = ({t, data}) => {
  console.log('ExportPdfAudit.data', data);
  //helpers
  const getAuditLabel = type => {
    const foundType = AuditTypes.find(t => t.value === type);
    return foundType ? foundType.label : type;
  }


  return (
  <Document>
    <Page style={styles.body}>
      {/*<Text style={styles.header} fixed>~ {t("Created by")} {EXT_TITLE} {VERSION} ~</Text>*/}
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableColDateHeader}>
            <Text style={styles.tableCellHeader}>{t("Date")}</Text>
          </View>
          <View style={styles.tableColTypeHeader}>
            <Text style={styles.tableCellHeader}>{t("Action Type")}</Text>
          </View>
          <View style={styles.tableColUserHeader}>
            <Text style={styles.tableCellHeader}>{t("Username")}</Text>
          </View>
          <View style={styles.tableColDetailsHeader}>
            <Text style={styles.tableCellHeader}>{t("Details")}</Text>
          </View>
          <View style={styles.tableColArgsHeader}>
            <Text style={styles.tableCellHeader}>{t("Arguments")}</Text>
          </View>
        </View>
        {
          data.map(audit => (
            <View key={audit.id} style={styles.tableRow}>
              <View style={styles.tableColDate}>
                <Text style={styles.tableCellHeader}>{moment(audit.createdTimestamp).format("YYYY/MM/DD HH:mm:ss")}</Text>
              </View>
              <View style={styles.tableColType}>
                <Text style={styles.tableCellHeader}>{getAuditLabel(audit.actionType)}</Text>
              </View>
              <View style={styles.tableColUser}>
                <Text style={styles.tableCellHeader}>{audit.username}</Text>
              </View>
              <View style={styles.tableColDetails}>
                <Text style={styles.tableCellHeader}>{audit.details}</Text>
              </View>
              <View style={styles.tableColArgs}>
                <Text style={styles.tableCellHeader}>{JSON.stringify(audit.args).length > 200 ? JSON.stringify(audit.args, null, 1) : JSON.stringify(audit.args)}</Text>
              </View>
            </View>
          ))
        }
      </View>
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed />
    </Page>
  </Document>
)};



export default withTranslation()(ExportPdfAudit);