import moment from "moment";

class ExportJson {
  constructor(data) {
    this.rawData = {...data};
    this.fileName = 'export'
  }
  prepareProfileList() {
    this.fileName = "PROFILES_" + moment().format("YYYY_MM_DD_HH_mm_ss");
    return this;
  }
  prepareProfile() {
    const {target} = this.rawData;
    this.fileName = "PROFILE_" + target?.phone + '_' + moment().format("YYYY_MM_DD_HH_mm_ss");
    return this;
  }
  export() {
    /*const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.data));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", this.fileName + ".json");
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();


    return this;*/

    const url = window.URL.createObjectURL(new Blob([this.rawData.file]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', this.fileName + ".json");
    document.body.appendChild(link);
    link.click();

    return this;
  }
}

export default ExportJson