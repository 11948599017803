import React from "react";
import styled from "styled-components";
import { themes } from "../../../../config/Themes";

const Separator = styled.div`
  width: 1px;
  height: 26px;
  margin: 0 10px;
  background: ${themes.regular.dark_blue};
`;

export default () => <Separator />;