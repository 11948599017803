import styled, { css } from "styled-components";
import { themes } from "../../../../../config/Themes";
import { Link } from "react-router-dom";
import Search from "../../../../../assets/img/search_white.png";
import Query from "../../../../../assets/img/search_query.png";

export const Container = styled.div`
  background-color: ${themes.regular.light_blue_bg};
  border-radius: 5px;
  margin: 0;
  padding: 1em 0;
  box-sizing: border-box;
  position: absolute;
  right: -100%;
  top: 56px;
  width: 180px;
  z-index: 10;
  display: flex;
  flex-flow: column;
`;

export const UserLink = styled(Link)`
  color: ${themes.regular.white};
  font-size: 0.85em;
  line-height: 1.5;
  padding: 10px 0 10px 60px;

  :hover {
    color: ${themes.regular.white};
    opacity: 1;
  }

  ${props =>
    props.name === "Search" &&
    css`
      background: url(${Search}) no-repeat 1.5em center;
      :hover {
        background: url(${Search}) no-repeat 1.5em center,
          ${themes.regular.blue_bg};
      }
      ${props =>
        props.isactive === true &&
        css`
          background: url(${Search}) no-repeat 1.5em center,
            ${themes.regular.blue_bg};
        `}
    `}

  ${props =>
    props.name === "Query" &&
    css`
      background: url(${Query}) no-repeat 1.5em center;
      :hover {
        background: url(${Query}) no-repeat 1.5em center,
          ${themes.regular.blue_bg};
      }
      ${props =>
        props.isactive === true &&
        css`
          background: url(${Query}) no-repeat 1.5em center,
            ${themes.regular.blue_bg};
        `}
    `}
`;
