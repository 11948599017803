import ActionUtility from "../ActionUtility";
import nextId from "react-id-generator";

export default class UsersAction {
  static GET_USER_LIST = "UsersAction.GET_USER_LIST";
  static SET_USER_LIST = "UsersAction.SET_USER_LIST";
  static CREATE_USER = "UsersAction.CREATE_USER";
  static EDIT_USER = "UsersAction.EDIT_USER";
  static DELETE_USER = "UsersAction.DELETE_USER";
  static SET_USERS_LOADING = "UsersAction.SET_USERS_LOADING";

  static getUserList(data) {
    console.log('action', data);
    return ActionUtility.createAction(UsersAction.GET_USER_LIST, data);
  }

  static setUserList(page) {
    return ActionUtility.createAction(UsersAction.SET_USER_LIST, page);
  }

  static createUser(user) {
    const { userName, password, admin, user: userRole, currentPage = 0 } = user;
    let roles = [];
    admin && roles.push("ADMIN");
    userRole && roles.push("USER");

    const newUser = {
      userName: userName,
      password,
      roles: roles,
      currentPage
    };

    return ActionUtility.createAction(UsersAction.CREATE_USER, newUser);
  }

  static editUser(newInfo) {
    const { id, userName, password, admin, user: userRole, currentPage = 0 } = newInfo;
    let roles = [];
    admin && roles.push("ADMIN");
    userRole && roles.push("USER");

    const editedUser = {
      id,
      userName,
      password,
      roles: roles,
      currentPage
    };
    return ActionUtility.createAction(UsersAction.EDIT_USER, editedUser);
  }

  static deleteUser(userData) {
    return ActionUtility.createAction(UsersAction.DELETE_USER, userData);
  }

  static setUsersLoading(state) {
    return ActionUtility.createAction(UsersAction.SET_USERS_LOADING, state);
  }
}
