import styled from "styled-components";
import { themes } from "../../../config/Themes";

export const Header = styled.div`
  background-color: ${themes.regular.blue_bg};
  height: 56px;
  width: 100%;
  color: ${themes.regular.white};
`;

export const HeaderMenu = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  margin: auto;

  @media (min-width: 1600px) {
    max-width: 80%;
  }

  @media (max-width: 1440px) {
    justify-content: flex-end;
  }
`;

export const BadgeWrapper = styled.div`
  position: absolute;
  top: 2px;
  right: 8px;
`;